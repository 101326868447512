import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { selectUserId, selectUserName, selectOrgId } from "../../features/auth/authSlice";
import { selectNameString } from "../../features/auth/roleSlice";
import GetAudienceData from "../GetAudienceData";
import { useGetUserAudiencePlatformsQuery , useSelectAudienceByIdMutation} from "../../app/api";
import { useSelectAudienceMultiFormatQuery } from "../../app/api";
//import { useGetUserAudiencePlatformsQuery, useAddPlatformFeedbackMutation, useSendEmailMutation, useGetConfigurableValueQuery } from "../../app/api";
import { useGetUserAudienceByAudienceIdAndUserIdQuery, useOrgByOrgIdQuery } from "../../app/api";
import ActivatedStepOne from "./ActivatedStepOne";
import ActivatedStepTwo from "./ActivatedStepTwo";
import ActivatedStepThree from "./ActivatedStepThree";
import { formatAudienceTitle, numberWithCommas } from "../userSettings";
import { liveRampPlatforms } from "../../data/ActivateData";


export default function Activated() {
  const userId = useSelector(selectUserId);
  const userEmail = useSelector(selectUserName);
  const userName = useSelector(selectNameString); 
  const orgId = useSelector(selectOrgId);

  const [thisAudience, setThisAudience] = useState({});
  const [selectedStep, setSelectedStep] = useState(1);
 
  const queryParameters = new URLSearchParams(window.location.search);
  const audienceUUID = queryParameters.get("uuid");
  const overrideUserId = queryParameters.get("override_id"); 
  const {data: existingMultiFormats} = useSelectAudienceMultiFormatQuery({uuid: thisAudience.uuid}, {skip: !thisAudience});
  // activation parameters
  const [activationType, setActivationType] = useState(null);
  const [activationDesc, setActivationDesc] = useState(null);
  const [activationID, setActivationID] = useState(null);
  const [activationAccountID, setActivationAccountID] = useState(null);
  const [activationStartDate, setActivationStartDate] = useState(null);
  const [activationEndDate, setActivationEndDate] = useState(null);
  const [activationCostDesc, setActivationCostDesc] = useState(null);
  const [activationCost, setActivationCost] = useState(null);
  const [activationBillingRate, setActivationBillingRate] = useState(null);
  const [activationAuthTimestamp, setActivationAuthTimestamp] = useState(null);



  const [myActivations, setMyActivations] = useState([]);
  const { data: activations, isSuccess: activationSuccess } =  useGetUserAudiencePlatformsQuery({user_id: overrideUserId? overrideUserId : userId, audience_id: thisAudience.id}, {skip: !thisAudience.id, refetchOnMountOrArgChange: true});
  //const [addPlatformFeedback] = useAddPlatformFeedbackMutation();
  const customAudience = queryParameters.get("custom");
 // const [showMissingPlatformFeedback, setShowMissingPlatformFeedback] = useState(false);
 // const [missingPlatformFeedback, setMissingPlatformFeedback] = useState('');
 // const [missingPlatformFeedbackSubmitted, setMissingPlatformFeedbackSubmitted] = useState(false);
 // const [missingPlatformFeedbackErrorStatus, setMissingPlatformFeedbackErrorStatus] = useState(false);
 // const [sendEmail] = useSendEmailMutation();
 // const {data: platformFeedbackRecipientData} = useGetConfigurableValueQuery({configurable_name: 'email.platform_feedback.recipient'});
 // const [platformFeedbackRecipient, setPlatformFeedbackRecipient] = useState('andrea@incubate.pro');

  // Get user's audience info
  const {data: userAudienceData} = useGetUserAudienceByAudienceIdAndUserIdQuery({audience_id: thisAudience.id, user_id: userId }, {skip: !thisAudience.id, refetchOnMountOrArgChange: true});
  const [thisUserAudience, setThisUserAudience] = useState({});
  const {data: overrideUserAudienceData} = useGetUserAudienceByAudienceIdAndUserIdQuery({audience_id: thisAudience.id, user_id: overrideUserId }, {skip: !thisAudience.id, refetchOnMountOrArgChange: true});
  const [thisOverrideUserAudience, setThisOverrideUserAudience] = useState({});
  const {data: orgData} = useOrgByOrgIdQuery({org_id: orgId});
  const [org, setOrg] = useState({});

  const [getOrigAudience] = useSelectAudienceByIdMutation();
  const [origAudience, setOrigAudience] = useState(null);

  useEffect(()=> {
    if (userAudienceData && thisAudience.id) {
      setThisUserAudience(userAudienceData);
    }
  }, [userAudienceData, thisAudience]);

  useEffect(()=> {
    if (overrideUserAudienceData && thisAudience.id) {
      setThisOverrideUserAudience(overrideUserAudienceData);
    }
  }, [overrideUserAudienceData, thisAudience]);

  useEffect(() => {
    if (orgData) {
      setOrg(orgData);
    }
  }, [orgData]);
  
  // activation ID is the platform ID
  // acctivation Account ID is the customers account ID for that platform
  const activationJSON = {activationType: activationType, activationDesc: activationDesc, activationID: activationID, activationAccountID: activationAccountID, 
    activationStartDate: activationStartDate, activationEndDate: activationEndDate, activationBillingRate: activationBillingRate, activationCostDesc: activationCostDesc, activationCost: activationCost,
    activationBillingContactName: userName, activationBillingEmailAddress: userEmail, 
    activationContactEmailAddress: userEmail, activationAuthTimestamp: activationAuthTimestamp,
    activationRecords: thisAudience?.count || thisUserAudience?.records_uploaded || thisOverrideUserAudience?.records_uploaded
  }

// TODO: missing platform removed in new design
  /*const handleMissingPlatformFeedbackChange = (event) => {
    const value = event.target.value;
    setMissingPlatformFeedback(value);
    if (missingPlatformFeedbackErrorStatus && value.length > 0) {
      setMissingPlatformFeedbackErrorStatus(false);
    }
  };*/ 

  const runOrig = async (a_id) => {
    const oAudience = await getOrigAudience({audience_id: a_id});
    setOrigAudience(oAudience.data);
    console.log('original audience is:', origAudience, oAudience.data);
  }

  useEffect( () => {
    if (thisAudience.base_audience_id || thisAudience.id) {
      console.log('checking for orig with', thisAudience, thisAudience.base_audience_id, thisAudience.id);
      runOrig(thisAudience.base_audience_id ||  thisAudience.id);
    }
  }, [thisAudience]);

  useEffect( () => {
    if (activations) {
      setMyActivations(activations);
    }}
    , [activationSuccess]);

/*
  useEffect(() => {
    if (platformFeedbackRecipientData) {
      setPlatformFeedbackRecipient(platformFeedbackRecipientData.config_value);
    }
  }, [platformFeedbackRecipientData]);
  */

  const setBillingAndAuth = (authTimeStamp) => {
    setActivationAuthTimestamp(authTimeStamp);
    setSelectedStep(3);
  }

  const setActivePlatform = (type, platformId, platformDesc, refId, startDate, endDate) => {
    if (type == 'platform') {
      console.log("settingPlatform upper", platformId);
      setActivationType('platform');
      setActivationDesc(platformDesc);
      setActivationID(platformId);
      setActivationStartDate(startDate);
      setActivationEndDate(endDate);
      setActivationAccountID(refId);
      if (orgData && orgData.free_version ) {
        if (origAudience.audience_category == 'MULTI-ISSUE') {
          setActivationBillingRate(orgData.multi_issue_cpm.toFixed(3));
          setActivationCostDesc('$' + numberWithCommas(orgData.multi_issue_cpm.toFixed(3)) + ' CPM');
        }
        else {
          setActivationBillingRate(orgData.single_issue_cpm.toFixed(3));
          setActivationCostDesc('$' + numberWithCommas(orgData.single_issue_cpm.toFixed(3)) + ' CPM');
        }
      } else {
        setActivationBillingRate(origAudience.audience_cpm.toFixed(2));
        setActivationCostDesc('$' + origAudience.audience_cpm.toFixed(2) + ' CPM');
      }

      // calculate cost:
      let date1 = new Date(startDate);
      let date2 = new Date(endDate);

      let Difference_In_Time = date2.getTime() - date1.getTime();

      // Calculating the no. of days between
      let Difference_In_Days =
         Math.round
        (Difference_In_Time / (1000 * 3600 * 24));

      // Calc # of months:
      let MonthAvg = Difference_In_Days / 30;

      console.log('month avg is:', MonthAvg, Difference_In_Days);
      // cost calc from MVD:
      // Formula = ((number of records/1000)*CPM) * Number of Months

      let cost = 0;
      if (orgData && orgData.free_version) {
        if (origAudience.audience_category == 'MULTI-ISSUE') {
          cost = ((activationJSON.activationRecords/1000)* orgData.multi_issue_cpm) * MonthAvg;
        }
        else {
          cost = ((activationJSON.activationRecords/1000)* orgData.single_issue_cpm) * MonthAvg;
        }
      }
      else {
        cost = ((activationJSON.activationRecords/1000)* origAudience.audience_cpm) * MonthAvg;
      }

      setActivationCost(cost);
      setSelectedStep(2);
    }
    else {
      console.log("settingPlatform upper 2", platformId);
      setActivationType('export');
      setActivationDesc(platformDesc);
      setActivationID(platformId);
      //Records from MVD
      /*
        Cost
        1,001 to 10,000    $0.09 per record
        10,001 to 100,000  $0.08 per record
        100,001 to 1M      $0.07 per record
        1M to 10M          $0.05 per record
        10M to 100M        $0.03 per record
        100M+              Flat rate available. Pricing can be as low as $0.02 per record or negotiated based on specific requirements
      */

      const r = activationJSON.activationRecords;
      console.log('records', r);
      if (orgData && orgData.free_version) {
        switch (true) {
          case (r <= 10000):
            setActivationCostDesc(org.record_count_1k_to_10k_price + ' per record');
            setActivationBillingRate(org.record_count_1k_to_10k_price);
            setActivationCost(r * org.record_count_1k_to_10k_price);
            break;
          case (10001 <= r) && (r <= 100000):
            setActivationCostDesc(org.record_count_10k_to_100k_price + ' per record');
            setActivationBillingRate(org.record_count_10k_to_100k_price);
            setActivationCost(r * org.record_count_10k_to_100k_price);
            break;
          case (100001 <= r) && (r <= 1000000):
            setActivationCostDesc(org.record_count_100k_to_1m_price + ' per record');
            setActivationBillingRate(org.record_count_100k_to_1m_price);
            setActivationCost(r * org.record_count_100k_to_1m_price);
            break;
          case (1000001 <= r) && (r <= 10000000):
            setActivationCostDesc(org.record_count_1m_to_10m_price + ' per record');
            setActivationBillingRate(org.record_count_1m_to_10m_price);
            setActivationCost(r * org.record_count_1m_to_10m_price);
            break;
          case (10000001 <= r) && (r <= 100000000):
            setActivationCostDesc(org.record_count_10m_to_100m_price + ' per record');
            setActivationBillingRate(org.record_count_10m_to_100m_price);
            setActivationCost(r * org.record_count_10m_to_100m_price);
            break;
          case (r >= 100000001):
            setActivationCostDesc(org.record_count_100m_plus_price + ' per record');
            setActivationBillingRate(org.record_count_100m_plus_price);
            setActivationCost(r * org.record_count_100m_plus_price);
            break;
        }
      } 
      else {           
        switch (true) {
          case (r <= 10000):
            setActivationCostDesc('0.09 per record');
            setActivationBillingRate(0.09);
            setActivationCost(r * 0.09);
            break;
          case (10001 <= r) && (r <= 100000):
            setActivationCostDesc('0.08 per record');
            setActivationBillingRate(0.08);
            setActivationCost(r * 0.08);
            break;
          case (100001 <= r) && (r <= 1000000):
            setActivationCostDesc('0.07 per record');
            setActivationBillingRate(0.07);
            setActivationCost(r * 0.07);
            break;
          case (1000001 <= r) && (r <= 10000000):
            setActivationCostDesc('0.05 per record');
            setActivationBillingRate(0.05);
            setActivationCost(r * 0.05);
            break;
          case (10000001 <= r) && (r <= 100000000):
            setActivationCostDesc('0.03 per record');
            setActivationBillingRate(0.03);
            setActivationCost(r * 0.03);
            break;
          case (r >= 100000001):
            setActivationCostDesc('0.02 per record');
            setActivationBillingRate(0.02);
            setActivationCost(r * 0.02);
            break;
        }
      }

      setSelectedStep(2);
    }
  }

/*
  async function savePlatformFeedback() {
    let platform_feedback = await addPlatformFeedback({ user_id: userId, request: missingPlatformFeedback });
    if (!platform_feedback) {
      console.log("error getting platform_feedback");
    }
  }

  const processMissingPlatformFeedback  = () => {
     if (missingPlatformFeedback == '') { // Confirm value exists
       setMissingPlatformFeedbackErrorStatus(true);
     }
     else {
        savePlatformFeedback();
        // Send Platform Feedback email
        sendEmail({
          template_name: 'platform_feedback', 
          recipient_to: platformFeedbackRecipient,
          recipient_cc: '',
          subject: 'New Platform Feedback',
          template_variables: { username: username, user_id: userId, platform_feedback: missingPlatformFeedback }
        });
        setMissingPlatformFeedbackSubmitted(true); 
        setMissingPlatformFeedbackErrorStatus(false);
     }
  }
*/

  const list =  () => { 
    let stringName = '';
    myActivations.map((t) => (
      stringName = stringName + liveRampPlatforms.find(l => l.id == t.platform_id)?.text + ', '
    ));
    return stringName.slice(0, -2) ;
  }

  const exportList =  () => { 
    let stringName = '';
    console.log('existingMultiFormats', existingMultiFormats);
    existingMultiFormats.map((t) => (
        stringName = stringName + formatExportType(t.format) + ', '
    ));
    return stringName.slice(0, -2) ;
  }

  const formatExportType = (exportType) => {
    if (exportType == 'ids') {
      return "LALIDS";
    }
    else {
      return exportType
    }
  }

  const activeList = () => {
    if (myActivations.length > 0) {
      return (
        <>
          <p className='my-acts'>Currently active or in progress on {list()}</p>
        </>
      )
    }
  }
  const exportActiveList = () => {
    if (existingMultiFormats?.length > 0) {
      return (
        <>
          <p className='my-acts'>Downloads existing or in progress on {exportList()}</p>
        </>
      )
    }
  }
/*
  const showMissingPlatform = () => {
    if (showMissingPlatformFeedback) {
     setShowMissingPlatformFeedback(false);
    }
    else {
     setShowMissingPlatformFeedback(true);
    }
  }

  const missingPlatform = () => {
    return (
      <div className="platform-missing">
        <div className={missingPlatformFeedbackSubmitted? 'hidden' : ''}>
          <button className={showMissingPlatformFeedback? 'show-missing-platform' : 'show-missing-platform collapsed'} onClick={() => showMissingPlatform()}>Missing A Platform?</button>
          <div className={showMissingPlatformFeedback? '' : 'hidden'}>
            <p>Do not see the platform you are looking for? Please let us know.</p>
            <textarea onChange={handleMissingPlatformFeedbackChange} placeholder="Enter Platform Feedback"></textarea>
            <label className={missingPlatformFeedbackErrorStatus ? 'error show': 'error'}>Feedback cannot be blank </label>
            <div className="platform-missing-button-wrapper">
              <button onClick={() => processMissingPlatformFeedback()}>
                <p>Submit Request</p>
              </button>
            </div>
          </div>
        </div>
        <div className={missingPlatformFeedbackSubmitted? '' : 'hidden'}>
          <h4>Request Submitted</h4>
          <p>Thank you for your feedback!</p>
        </div>
      </div>      
    )  
  }
  */

 
  return (
    <div className='h-100'>
      <div className='w-100'>
        <h1 className='activate-audience-title'> Activate { formatAudienceTitle(thisAudience?.title) }</h1>
      </div>
    <div className="client_dashboard_view_content content_menu_wrapper no_title">
      <GetAudienceData audienceUUID={audienceUUID} callback={setThisAudience} getAudience={true} getCustomAudience={customAudience == 1 ? true: false} overrideUserId={overrideUserId} />
      <div className='client_dashboard_view_content_menu activation'>
        <div className='content_menu_body no_padding full-width'>
          <div className='activation menu-width'>
            <div className='secondary_sidebar activations_sidebar activation '>
              <div className='internal-activation-box'>
                <p className='audience-description line-height-22 mt-0'> {thisAudience?.description} </p>
                <div className='activation_sidebar_content_wrapper'>
                  <div className='records_wrapper'>
                    <i className='fa fa-line-chart'/>
                    <h3 className=''> {numberWithCommas(thisAudience?.count) || numberWithCommas(thisUserAudience?.records_uploaded) || numberWithCommas(thisOverrideUserAudience?.records_uploaded)} Records </h3> 
                  </div>
                  {activeList()}
                  {exportActiveList()}
                </div>
              </div>
            </div>
          </div>
          <hr className='separator'/>
          <div className='activation menu-width'>
            <div className='secondary_sidebar activations_sidebar activation'>
                <div className='activation-step-div'>
                  <div className={selectedStep > 1 ? 'activation-steps expanded' : 'activation-steps'} >
                    {selectedStep >= 1 && <i className='fa-light fa-circle circle-step selected-next'/>}
                    <i className={selectedStep >= 1 ? 'fa-solid fa-circle circle-step selected' : 'fa-light fa-circle circle-step' }>
                      <p>{selectedStep == 1 ? 1 : <i className='fa fa-check'></i>}</p> 
                    </i>
                    <p className={selectedStep >= 1 ? 'selected': ''}> {selectedStep >=1 ? 'Selected platform' : 'Select platform'} </p>
                    <div className={selectedStep > 1 ? 'horizontal-rule extended' : 'horizontal-rule'}/>
                    { selectedStep > 1 && 
                      <div>
                        <ul>
                          {activationType == 'export' && <li>Data export (.csv)</li>}
                          <li>{activationDesc}</li>
                          {activationType == 'platform' && <li>{activationStartDate} to {activationEndDate}</li>}
                        </ul>
                      </div>
                    }
                  </div>
                  <div className={selectedStep > 5 ? 'activation-steps expanded' : 'activation-steps'} >
                    {selectedStep >= 2 && <i className='fa-light fa-circle circle-step selected-next'/>}
                    <i className={selectedStep >= 2 ? 'fa-solid fa-circle circle-step selected' : 'fa-light fa-circle circle-step'}>
                      <p>{selectedStep <= 2 ? 2 : <i className='fa fa-check'></i>}</p> 
                    </i>
                    <p className={selectedStep >= 2 ? 'selected': ''}>  Authorize and confirm </p>
                    <div className={selectedStep > 5 ? 'horizontal-rule extended' : 'horizontal-rule'}/>
                    { selectedStep > 5 && 
                      <div>
                        <ul>
                          <li>auth on: {activationAuthTimestamp}</li>
                        </ul>
                      </div>
                    }
                  </div>
                  <div className='activation-steps' >
                    {selectedStep >= 3 && <i className='fa-light fa-circle circle-step selected-next'/>}
                    <i className={selectedStep >= 3 ? 'fa-solid fa-circle circle-step selected' : 'fa-light fa-circle circle-step' }>
                      <p>{selectedStep <= 3 ? 3 : <i className='fa fa-check'></i>}</p> 
                    </i>
                    <p className={selectedStep == 3 ? 'selected': ''}>{orgData?.free_version? 'Activation confirmed' : 'Enter billing information'}</p>
                    { selectedStep >= 3 && 
                      <div>
                        <ul>
                          <li>Billing Rate: {activationCostDesc}</li>
                          <li>Total Cost: ${numberWithCommas(activationCost)}</li>
                        </ul>
                      </div>
                    }
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>  
      <div className='client_dashboard_view_content_view_with_menu no_padding_view shorter_view'>  
        <div className="platform-activations">
          <ActivatedStepOne setActivePlatform={setActivePlatform} activationJSON={activationJSON} thisAudience={thisAudience} selectedStep={selectedStep} existingMultiFormats={existingMultiFormats} origAudience={origAudience} orgData={orgData}/>
          <ActivatedStepTwo setActivePlatform={setActivePlatform} setSelectedStep={setSelectedStep} activationDesc={activationDesc} activationType={activationType} callback={setBillingAndAuth} activationJSON={activationJSON} thisAudience={thisAudience} selectedStep={selectedStep} orgData={orgData}/>
          <ActivatedStepThree callback={setBillingAndAuth} setSelectedStep={setSelectedStep} activationJSON={activationJSON} thisAudience={thisAudience} thisUserAudience={thisUserAudience} selectedStep={selectedStep} existingMultiFormats={existingMultiFormats} thisOverrideUserAudience={thisOverrideUserAudience} orgData={orgData} />
        </div>
      </div>
    </div>
    </div>
  )
}
import React from "react";
import PropTypes from "prop-types";
import { liveRampPlatforms } from "../../data/ActivateData";
import { platformImages, platformImage } from "./ActivatedStepOne";
import { numberWithCommas } from "../userSettings";
import { useNavigate, createSearchParams } from "react-router-dom";
import { formatSelectedFilters } from "../userSettings";

export default function ActivatedSummary({activationJSON, thisAudience, success}) {

   const navigate = useNavigate();

   const editAudienceFilter = () => {
    return (
    <button className={success? 'hidden' :'filter-this-audience'} onClick={() => {navigate({ pathname: '/dashboard/audience', search: createSearchParams({ uuid: thisAudience.uuid, cView: thisAudience.cluster, custom: thisAudience.custom }).toString() })}}>
      <i className='fa-light fa-filter'></i>
      Edit audience filters
    </button>
    )
   }

  return (
    <div className='container p-1 sides-only'>
      <div className='row '>
        <div className='leftside w-100'>
          { activationJSON.activationType == 'export' ? 
          <table className='billing-table'>
            <tbody>
              <tr>
                <td><b>Data Download Type:</b> {activationJSON.activationDesc}</td>
              </tr>
              <tr>
                <td><b>Selected Filters:</b> {formatSelectedFilters(thisAudience)}</td>
              </tr>
              <tr>
                <td>
                  {editAudienceFilter()}
                </td>
              </tr>
              <tr>
                <td>
                   <b>Records:</b>  {numberWithCommas(activationJSON.activationRecords)}
                </td>
              </tr>
              <tr>
                <td><b>Billing Rate:</b> {activationJSON.activationCostDesc}</td>
              </tr>
              <tr>
                <td><b>Total Cost:</b> ${numberWithCommas(activationJSON.activationCost)}</td>
              </tr>
          </tbody>
        </table>
        :
          <>
            <table className='billing-table'>
              <tbody>
                <tr>
                  <td><b>Selected platform:</b> {activationJSON.activationDesc}</td>
                  <td><b>Campaign date range:</b> {activationJSON.activationStartDate} to {activationJSON.activationEndDate}</td>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    <div className='mt-1'>{platformImage(liveRampPlatforms, activationJSON.activationID, false)}
                    </div>
                  </td>
                  <td>
                    <b>Billing Rate:</b> {activationJSON.activationCostDesc}   
                  </td>
                </tr>
                <tr>
                  <td><b>Total Cost:</b> ${numberWithCommas(activationJSON.activationCost)}</td>
                </tr>
            </tbody>
          </table>
          <div className='platform_filters_wrapper'>
            <p><b>Selected Filters:</b> {formatSelectedFilters(thisAudience)}</p>
            {editAudienceFilter()}
          </div>
        </>
      }
      </div>
    </div>
    <div className='hidden'>
      {platformImages(liveRampPlatforms)}
    </div>
  </div>
  )
}


ActivatedSummary.propTypes = {
  activationJSON: PropTypes.object,
  thisAudience: PropTypes.object,
  success: PropTypes.bool
}
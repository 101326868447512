import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { selectUserId, selectOrgId} from "../../features/auth/authSlice";
import { useGetAudienceRequestsQuery, useGetCountCustomRequestsQuery, useUpdateAudienceStatusMutation, useCancelPlatformActivationMutation } from "../../app/api";
import { useGetAudienceExportRequestsQuery, useGetPresignedUrlMutation } from "../../app/api";
import { useNavigate, createSearchParams } from "react-router-dom";
import { selectSearchString } from "../../features/currentView/currentViewSlice";
import { numberWithCommas, formatAudienceTitle,formatAudienceTitleForActivationsList } from "../userSettings";
import { liveRampPlatforms } from "../../data/ActivateData";
import { formatSelectedFiltersActivations, capitalizeFirstLetter } from "../userSettings";
import { useGetSelectAllAudiencesQuery, useGetSalesByUserIdQuery, useOrgByOrgIdQuery } from "../../app/api";

export default function YourAudiences() {

  const userId = useSelector(selectUserId);
  const [requests, setRequests] = useState([]);
  const [customRequests, setCustomRequests] = useState([]);
  const [exportRequests, setExportRequests] = useState([]);
  const searchString = useSelector(selectSearchString);
  const [sales, setSales] = useState([]);
  const { data: salesData, isSuccess: salesDataSuccess } = useGetSalesByUserIdQuery({user_id: userId}); 
  const { data: audienceData, isSuccess: audienceSuccess } =  useGetSelectAllAudiencesQuery();
  const navigate = useNavigate();
  const orgId = useSelector(selectOrgId);

  const [tabView, setTabView] = useState('active');
  const [audienceListData, setAudienceListData] = useState([]);
  const { data: activations, isSuccess: activationSuccess } =  useGetAudienceRequestsQuery({user_id: userId});
  const { data: customActivations, refetch: runCustomActivationQuery, isSuccess: customActivationSuccess  } =  useGetCountCustomRequestsQuery({user_id: userId});
  const { data: exports, isSuccess: exportsSuccess } =  useGetAudienceExportRequestsQuery({user_id: userId});
  const [ cancelActivation ] = useCancelPlatformActivationMutation();
  const [ updateAudienceStatus ] = useUpdateAudienceStatusMutation();
  const [ getPresignedUrl ] = useGetPresignedUrlMutation();
  const {data: orgData} = useOrgByOrgIdQuery({org_id: orgId});

  useEffect( () => {
    if (audienceSuccess) {
      setAudienceListData(audienceData);
    }
  }, [audienceSuccess] );


  useEffect( () => {
    if (salesData) {
      setSales(salesData);
    }}
    , [salesDataSuccess]);

  useEffect( () => {
    if (activations) {
      setRequests(activations);
    }}
    , [activationSuccess]);

  useEffect( () => {
    if (customActivations) {
      setCustomRequests(customActivations);
    }}
    , [customActivationSuccess, customActivations]);

  useEffect( () => {
    if (exports) {
      setExportRequests(exports);
    }}
    , [exportsSuccess]);

    {liveRampPlatforms
          .filter(t => t.active == 0)
          .sort((a, b) => a.text.localeCompare(b.text))
          .map((h, i) => (
            <img key={i} src={"../../images/activation/"+h.image}/>
          ))
        }

  const downloadExport = async (s3_path, refNo) => {
    const download = await getPresignedUrl({s3_path: s3_path});
    console.log('S3 presigned is:', download);
    window.open(download.data, "_blank");
    await updateAudienceStatus({ref_no: refNo, status_value: 'DOWNLOADED'});
    runCustomActivationQuery();
  }

  const tabClass = (classList, thisTab) => {
    if (thisTab == tabView) {
      return classList + ' selected';
    }
    else {
      return classList + ' disabled';
    }
  }


  /* function confirmCancelActivation(reference_id, unique_id) {
    if (window.confirm("Are you sure you want to cancel on all Platforms?")) {
      cancelActivation(reference_id, unique_id);
      window.location.reload();
    }
  } */

  async function softDeleteAudience(refNo){
    if (window.confirm("Are you sure you want to delete this Audience?")) {
      await updateAudienceStatus({ref_no: refNo, status_value: 'DELETED'});
      runCustomActivationQuery();
    }
  }

  /*const box = (req) => {
      return (
        <ul id='box'>
          {req.user_audience_status != 'CANCELLED' &&
            <>
              <li>
                <button className='highlight-hover' onClick={() =>  {navigate({pathname:'/dashboard/audience/activate', search: createSearchParams({uuid: req.uuid, ref: req.reference_id, custom: req.custom}).toString()})}}>Add New Platform</button> 
              </li>
              <li>
                <button className='highlight-hover' onClick={() =>  {navigate({pathname:'/dashboard/your-audience-platforms', search: createSearchParams({uuid: req.uuid, ref: req.reference_id, orig_date: req.updated, custom: req.custom}).toString()})}}>View Details</button> 
              </li>
            </>
          }
          {req.user_audience_status !== 'CANCELLED' &&
            <li>
              <button className='highlight-hover' onClick={() =>  {confirmCancelActivation({reference_id: req.reference_id, unique_id: -1})}}>Cancel all platforms</button>
            </li>
          }
          
        </ul>
      )
  } */

  const determineRecordStatus = (req) => {
    if (req) {
      if (req?.request_type == 'MULTIFORMAT') {
        if (req?.user_audience_status == 'OPEN') {
          return 'Pending';
        }
        else {
          return capitalizeFirstLetter(req.user_audience_status);
        }
      } else {
        let current_datetime = new Date();
        if (req?.end_date ) {
          if (new Date(req.end_date) > current_datetime ) {
            return capitalizeFirstLetter(req.activation_status);
          }
          else {
            return 'Expired';
          }
        }
      }
    }
  }

  const platformImages = (liveRampPlatforms) => {
    /* Pre-loading images to cache - keep hidden */
    if (liveRampPlatforms) {
      return (
        <div className='hidden'>
          {liveRampPlatforms
            .filter(t => t.active == 0)
            .sort((a, b) => a.text.localeCompare(b.text))
            .map((h, i) => (
              <img key={i} src={"../../images/activation/"+h.image}/>
            ))
          }
        </div>
      )
    }
  }

  const platformImage = (liveRampPlatforms, req) => {    
    if (req && liveRampPlatforms) {
      if ( req.request_type == 'MULTIFORMAT') {
        let formattedExportTitleArray = req.title.split('-');
        if (formattedExportTitleArray) {
          if (formattedExportTitleArray[formattedExportTitleArray.length - 1].trim() == 'ids') {
            return "LALIDS";
          }
          else {
            return (
              <>
                {formattedExportTitleArray[formattedExportTitleArray.length - 1].trim()}
              </>
            )
          }
        } 
      }
      else {
        const thisPlatform = liveRampPlatforms.find(t => t.id == req.platform_id);
        if (!thisPlatform){
          return (<></>)
        }
        else {
          return (
            <>
              <img className={thisPlatform.text == 'X'? 'platform_x' : '' } src={"../../images/activation/"+thisPlatform.image}/>
            </>
          )
        }
      }
    }
  }

  const buildPlatformLauncher = (req) => {
    if (req) {
      if (req.request_type && req.request_type != 'MULTIFORMAT') {
        return (
          <>{determineRecordStatus(req) == 'Expired'? 
            <button className='platforms' onClick={() =>  {navigate({pathname:'/dashboard/audience/activate', search: createSearchParams({uuid: req.uuid, ref: req.reference_id, custom: req.custom}).toString()})}}><p className='client'>Add Platform</p></button> : ''}
          </>      
        )
      }
      else {
        if (req.s3_path) {
          return (
            <> 
              <button className='exports' onClick={() => {downloadExport(req.s3_path, req.reference_id)}}><p className='client'>Download</p></button>
            </>      
          )
        }
      }
    }
  }

  const formatOriginalAudienceTitle = (req) => {
    if (req && audienceListData) {
       const audienceMatch = audienceListData.find(t => t.audience == req.audience);
      if (!audienceMatch){
        return (<></>)
      }
      else {
        return (
          <>
            <p>{audienceMatch.title}</p>
          </>
        )
      }
    }
  }

  const formatFilters = (filter_query) => {
    if (filter_query) {
      let filtersFormatted = formatSelectedFiltersActivations(filter_query);
      if (filtersFormatted.length > 25) {
        return (
          <div className='filterPopup'>
            <div className='staticFilters'>{filtersFormatted.substring(0,24)}</div>
            <i className='fa fa-solid fa-info-circle'></i>
            <div className='filterPopupWrapper'>
              <p className='filter_title'>Audience Filters</p>
              <p>{filtersFormatted}</p>
            </div>
          </div>
        )
      }
      else {
        return (
          <p>{filtersFormatted}</p>
        )
      }
    }
  }

  const formatDateRange = (req) => {
    if (req) {
      if (req.start_date && req.end_date){
        return getFormattedDate(req.start_date) + "-" + getFormattedDate(req.end_date);
      }
    }
  }

  const getFormattedDate = (dateString) => {
    if (dateString) {
      let date = new Date(dateString);
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      return month + '/' + day + '/' + year;
    }
  }

  const formatNotes = (notes) => {
    if (notes) {
      if (notes.length > 25) {
        return (
          <div className='filterPopup'>
            <div className='staticFilters'>{notes.substring(0,24)}</div>
            <i className='fa fa-solid fa-info-circle'></i>
            <div className='filterPopupWrapper notes'>
              <p><b>Notes: </b>{notes}</p>
            </div>
          </div>
        )
      }
      else {
        return (
          <p>{notes}</p>
        )
      }
    }
  }

  function confirmCancelActivation(reference_id, unique_id) {
    if (window.confirm("Are you sure you want to cancel this activation?")) {
      if (unique_id) {
        let formatted_unique_id = parseInt(unique_id);
        cancelActivation({reference_id: reference_id, unique_id: formatted_unique_id});
      } {
        cancelActivation({reference_id: reference_id, unique_id: 0});
      }
      window.location.reload();
    }
  }

  function confirmCancelExport(reference_id) {
    if (window.confirm("Are you sure you want to cancel this export?")) {
      updateAudienceStatus({ref_no: reference_id, status_value: 'DELETED'});      
      window.location.reload();
    }
  }

  const cancellationButton = (req) => {
    if (req) {
      if (req.request_type == 'MULTIFORMAT'){
        return (
          <i className="fa-solid fa-trash" title="Delete Export" onClick={() =>  {confirmCancelExport(req.reference_id)}}></i>
        )
      }
      else {
        if (req.activation_status != 'CANCELLED') {
          return (
            <i className="fa-solid fa-trash" title="Delete Audience" onClick={() =>  {confirmCancelActivation(req.reference_id, req.unique_id)}}></i>
          )
        }
      }
    }
  }

  const formatBillingPrice = (req, orgData, audienceListData) => {
    if (req) {
      if (orgData && orgData?.free_version) { // Bypass Values
        if (req.request_type == 'MULTIFORMAT'){
          return req?.cost?.toString();
        }
        else {
          const audienceMatch = audienceListData?.find(t => t.audience == req.audience);
          if (audienceMatch?.audience_category == 'MULTI-ISSUE') {
            return orgData?.multi_issue_cpm?.toFixed(2);
          }
          else {
            return orgData?.single_issue_cpm?.toFixed(2);
          }
        }
      }
      else {
        if (req.request_type == 'MULTIFORMAT'){
          return req?.cost?.toFixed(2);
        }
        else {
          return req?.audience_cpm?.toFixed(2);
        }
      }
    }
  }

  const formatTotalPrice = (req, sales) => {
    if (req && sales) {
      if (req.request_type == 'MULTIFORMAT'){
        const costTotal = sales.find(t => t.user_audience_reference_id == req.reference_id);
        if (costTotal) {
          return costTotal.total.toFixed(2);
        }
      }
      else {
        const costTotal = sales.find(t => t.platform_activation_id == req.platform_activation_id);
        if (costTotal) {
          return costTotal.total.toFixed(2);
        }
      }
    }
  }

  const results = () => { 
    //const arrayForSort = [...requests];
    let concatAudiencesExportArrays = [];
    concatAudiencesExportArrays = concatAudiencesExportArrays.concat(activations);
    concatAudiencesExportArrays = concatAudiencesExportArrays.concat(exportRequests);

    return (
      concatAudiencesExportArrays 
      .filter(t => t.title.toLowerCase().includes(searchString.toLowerCase()))
      .sort((a,b) => b.updated.localeCompare(a.updated))
      .map((req, i) => (
        <React.Fragment key={i}>
          <tr className='activated-row' key={i}>
            <td className='titles'>{formatOriginalAudienceTitle(req)}</td>
            <td className='titles'>{formatAudienceTitleForActivationsList(req?.title)}</td>
            <td>{determineRecordStatus(req)}</td>
            <td>{numberWithCommas(req?.records_uploaded)} </td>
            <td>{platformImage(liveRampPlatforms, req)}</td>
            <td>{req?.account_id}</td>
            <td className='popup_static_width'>{formatFilters(req?.filter_query)}</td>
            <td className="extra_room">{formatDateRange(req)}</td>
            <td>${formatBillingPrice(req, orgData, audienceListData)}</td>
            <td>${numberWithCommas(formatTotalPrice(req, sales))}</td>
            <td>{ new Date(req?.created).toLocaleDateString() }</td>
            <td>{req?.account_name}</td>
            {<td>{req?.reference_id}</td>}
            <td className='popup_static_width'>{formatNotes(req?.note)}</td>
            <td className='platformLauncherDeleteWrapper'>{buildPlatformLauncher(req)} {cancellationButton(req)}</td>
          </tr>
        </React.Fragment>
      ))
    )
  }

  const cancelledResults = () => {
    const arrayForSort = [...requests];
    return (
      arrayForSort 
      .filter(t => t.user_id == userId)
      .filter(t => t.no_of_inactive_platforms > 0)
      .sort((a,b) => b.updated.localeCompare(a.updated))
      .map((req, i) => (
        <React.Fragment key={i}>
          <tr className='activated-row' key={i}>
            <td>{formatAudienceTitle(req.title)}</td>
            {/*<td>{req.description}</td>*/}
            <td className='2'>{numberWithCommas(req.records_uploaded)} Records</td>
            {/*<td>{req.reference_id}</td>*/}
            <td>{req.no_of_inactive_platforms}</td>
            {/* <td>{req.user_audience_status}</td> Removed in MVDIG-386 */}
          </tr>
        </React.Fragment>
      ))
    )
  }

  const resultTable = () => {
    if (requests.length > 0) {
      return (
        <div key={'base'} className='activationsExports'>
          <h3>Platform Activations / Data Exports</h3>
          <table className='activationExportTable'>
            <thead>
              <tr>
                <th className='titles client'>Audience</th>
                <th className='titles'><p>Custom Name</p></th>
                <th>Status</th>
                <th><p>Record Count</p></th>
                <th><p>Platform / Download</p></th>   
                <th><p>Account ID</p></th>   
                <th className="popup_static_width"><p>Audience Filters</p></th>  
                <th className="extra_room">Campaign Dates</th> 
                <th>Billed Rate</th>   
                <th>Total Cost</th>   
                <th><p>Transaction Date</p></th>  
                <th>Account</th> 
                <th>Ref ID</th>  
                <th className="popup_static_width">Notes</th> 
                <th><p></p></th>
              </tr>
            </thead>
            <tbody>
              <div className='hidden'>
                {platformImages(liveRampPlatforms)}
              </div>
              {results()}
            </tbody>
          </table>

          <div className='break'/><div className='break'/><div className='break'/><div className='break'/>
          <h3 className="hidden">Canceled Activations</h3>
          <table className='hidden'>
            <thead>
              <tr>
                <th>Audience</th>
                {/*<th>Description</th>*/}
                <th>Records</th>
                {/*<th>Ref ID</th>*/}
                <th>Platforms</th>
                {/* <th>Status</th> Removed in MVDIG-386 */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {cancelledResults()}
            </tbody>
          </table>


        </div>
      )
    }
    else {
      return (
        <h2 key={'base-none'}>No Activation history.</h2>
      )
    }
  }

  const customResults = () => { 
    const arrayForSort = [...customRequests];
    return (
      arrayForSort 
      .filter(t => t.user_id == userId)
      .sort((a,b) => b.updated.localeCompare(a.updated))
      .map((req, i) => (
        <React.Fragment key={i}>
          <tr className='activated-row' key={i}>
            <td className='titles'>{formatOriginalAudienceTitle(req)}</td>
            <td className="titles">{formatAudienceTitle(req.title)}</td>
            <td>Saved</td>
            <td>{numberWithCommas(req.records_uploaded)}</td>
            <td className='popup_static_width'>{formatFilters(req.filter_query)}</td>
            <td>
              <i className='fa-solid fa-square-up-right' title="View Audience" onClick={() => {navigate({pathname: '/dashboard/audience', search: createSearchParams({uuid: req.uuid, cView: 0, custom: 1}).toString()});}}></i> 
              <i className="fa-solid fa-trash" title="Delete Audience" onClick={() =>  {softDeleteAudience(req.reference_id)}}></i>
            </td>
          </tr>
        </React.Fragment>
      ))
    )
  }

  const customResultTable = () => {
    if (customRequests.length > 0) {
      return (
        <div key={'base'} className='activations_table'>
          <div className='break'/>
          <table className='activationExportTable'>
            <thead>
              <tr>
                <th className='titles client'>Audience</th>
                <th className='titles'><p>Custom Name</p></th>
                <th>Status</th>
                <th><p>Record Count</p></th>
                <th className="popup_static_width"><p>Audience Filters</p></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customResults()}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return (
        <h2 key={'base-none'}>No Activation history.</h2>
      )
    }
  }

  const exportResults = () => { 
    const arrayForSort = [...exportRequests];
    return (
      arrayForSort 
      .filter(t => t.user_id == userId)
      .filter(t => (t.user_audience_status == 'DOWNLOADED' || t.user_audience_status == 'READY' || t.user_audience_status == 'COMPLETE'))
      .sort((a,b) => b.updated.localeCompare(a.updated))
      .map((req, i) => (
        <React.Fragment key={i}>
          <tr className='activated-row' key={i}>
            <td onClick={() => {navigate({pathname: '/dashboard/audience', search: createSearchParams({uuid: req.orig_uuid, cView: 0, custom: req.orig_custom}).toString()});}} className='link'>{req.title.split('||')[0]} </td>
            <td>{req.title.split('-')[1]}</td>
            {/*<td>{req.description}</td>*/}
            {/*<td>{req.reference_id}</td>*/}
            <td>{new Date(req.updated).toLocaleDateString()}</td>
            <td>{req.note} </td>
            <td>{req.account_name} </td>
            <td>{numberWithCommas(req.records_uploaded)} </td>
            <td>{req.s3_path?.length > 1 ? <div className='activation-continue-button smaller' onClick={()=>{downloadExport(req.s3_path, req.reference_id)}}>Download</div> : 'Request Pending'}</td>
          </tr>
        </React.Fragment>
      ))
    )
  }

  const exportTable = () => {
    if (exportRequests.length > 0) {
      return (
        <div key={'base'} className='activations_table'>
          <div className='break'/>
          <table>
            <thead>
              <tr>
                <th>Audience</th>
                <th>Type</th>
                <th>Transaction Date</th>
                <th>Note</th>
                <th>Account Name</th>
                <th>Records</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {exportResults()}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return (
        <h2 key={'base-none'}>No Export history.</h2>
      )
    }
  }

  const tabViewScreen = () => {
    if (tabView == 'active') {
      return (
        <>
          {resultTable()}
        </>
      )
    }
    else { 
      if (tabView == 'export') {
        return (
          <>
            {exportTable()}
          </>
        )
      }
      else {
        return (
          <>
            {customResultTable()}
          </>
        )
      }
    }
  }

  return (
    <div>
      <div className='client_dashboard_view_header standard'>
        <div className='client_dashboard_header_menu'>
        <div className={tabClass('client_dashboard_header_menu_tab tab_no_title','active')} onClick = {() => {setTabView('active')}}>
          <div className={tabClass('tab-ui', 'active')}>
            <h3>Activations</h3>
            <div className={tabClass('tab-highlight', 'active')}></div>
          </div>
        </div>
        <div className='hidden' onClick = {() => {setTabView('export')}}>
          {/*tabClass('client_dashboard_header_menu_tab tab_no_title','export')*/}
          <div className={tabClass('tab-ui', 'export')}>
            <h3>Exports</h3>
            <div className={tabClass('tab-highlight', 'active')}></div>
          </div>
        </div>
        <div className={tabClass('client_dashboard_header_menu_tab tab_no_title', 'build')} onClick = {() => {setTabView('build')}}>
          <div className={tabClass('tab-ui', 'custom')}>
            <h3> Saved </h3>
            <div className={tabClass('tab-highlight', 'custom')}></div>
          </div>
        </div>
        </div>
      </div>
      <div className='client_dashboard_view_content'>
          {tabViewScreen()}
      </div> 
    </div>

  )
}
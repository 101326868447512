import React, { useState, useEffect } from "react";
import { useGetUserByUsernameQuery } from "../../app/api";
import { useResetPasswordMutation, useForgotPasswordMutation } from "../../app/api";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router"
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function ResetPasswordForm({ type, email, setShowPopup, invited }) {
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const { data: user, refetch: getUserAgain } = useGetUserByUsernameQuery({ username: email });
  const token = type == 'USER' ? user?.reset_token : useParams().token;
  const userId = type == 'USER' ? user?.id : useParams().id;

  const [resetPasswordToken, { isSuccess: tokenSuccess }] = useForgotPasswordMutation();
  const [resetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();

  // Validation
  const [newPasswordErrorStatus, setNewPasswordErrorStatus] = useState(false);
  const [newPasswordComplexityErrorStatus, setNewPasswordComplexityErrorStatus] = useState(false);
  const [repeatPasswordErrorStatus, setRepeatPasswordErrorStatus] = useState(false);
  const [passwordMismatchErrorStatus, setPasswordMismatchErrorStatus] = useState(false);

  // Wording depending on workflow (Reset password vs new user invitation password)
  const heading = invited ? "Change Password" : "Reset Password";
  const submit = invited ? "Set Password" : "Reset";
  const successMessage = invited ? 'Your password has been saved' : 'Your password has been reset.';

  useEffect(() => {
    // if reset password is being triggered by the user dashboard interface, reset token time automatically //
    if (type === 'USER') {
      console.log('running token reset');
      resetPasswordToken({ email: email, send_email: false });
    }
  }, []);

  useEffect(() => {
    console.log('getting user again');
    getUserAgain({ username: email });
  }, [tokenSuccess]);

  const handleNewPasswordChange = (event) => {
    const value = event.target.value;
    setNewPassword(value);
    if (newPasswordErrorStatus && value.length > 0) {
      setNewPasswordErrorStatus(false);
    }
  };
  const handleRepeatPasswordChange = (event) => {
    const value = event.target.value;
    setRepeatNewPassword(value);
    if (repeatPasswordErrorStatus && value.length > 0) {
      setRepeatPasswordErrorStatus(false);
    }
  };

  const passwordValidation = () => {
    let failed_validation = false;

    if (newPassword == '') { // passwordConfirm empty
      setNewPasswordErrorStatus(true)
      failed_validation = true
    }
    else {
      if (!isPasswordComplex(newPassword)) { // password complexity
        setNewPasswordComplexityErrorStatus(true);
        failed_validation = [true]
      }
      else {
        setNewPasswordComplexityErrorStatus(false);
      }
    }
    if (repeatNewPassword == '') { // passwordConfirm empty
      setRepeatPasswordErrorStatus(true);
      failed_validation = true
    }
    else {
      if (newPassword != repeatNewPassword) { // verify password match 
        setPasswordMismatchErrorStatus(true);
        failed_validation = true
      }
      else {
        setPasswordMismatchErrorStatus(false);
      }
    }
    return !failed_validation;
  }


  const isPasswordComplex = (password) => {
    let passwordStength = 0;

    // validate lowercase 
    if (password.match(/[a-z]/)) {
      passwordStength += 1;
    }
    // validate uppercase 
    if (password.match(/[A-Z]/)) {
      passwordStength += 1;
    }
    // validate number 
    if (password.match(/([0-9])/)) {
      passwordStength += 1;
    }
    // valifate special character
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      passwordStength += 1;
    }
    // validate 8 character length
    if (password.length > 7 && passwordStength >= 3) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Handle submitting the Reset Password form
   */
  async function onResetPassword(event) {
    event.preventDefault();
    if (passwordValidation()) {
      try {
        await resetPassword({ userId, newPassword, token })
          .unwrap()
          .then(() => {
            if (type === 'USER') {
              setMessage(successMessage + ' Please log out and back in again.');
            }
            else {
              const urlParams = new URLSearchParams({
                message: successMessage
              });
              navigate(`/login?${urlParams}`);
            }
          })
          .catch((error) => setMessage(error.data.detail));
      } catch (validationError) {
        setMessage(validationError);
      }
    }
  }

  const determinResetPasswordLayout = (type) => {
    
    if (type === 'USER') {
      return (
        <form className='auth-form-popup'>
          <div className='full-page centered top-bump-40'>
            <h2>{heading}</h2>
          </div>
          <div className='login_entry_wrapper'>
            <input
              autoFocus
              type="password"
              placeholder="New Password *"
              value={newPassword}
              onChange={(e) => handleNewPasswordChange(e)} required
            />
            <label className={newPasswordErrorStatus ? 'error show' : 'error'}>Enter password</label>
            <label className={newPasswordComplexityErrorStatus ? 'error show' : 'error'}>Please enter a valid password with a minimum of 8 characters of uppercase lowercase, and special character</label>
          </div>
          <div className='login_entry_wrapper'>
            <input
              type="password"
              placeholder="Confirm New Password *"
              value={repeatNewPassword}
              onChange={(e) => handleRepeatPasswordChange(e)} required
            />
            <label className={repeatPasswordErrorStatus ? 'error show' : 'error'}>Enter password </label>
            <label className={passwordMismatchErrorStatus ? 'error show' : 'error'}>Password mismatch</label>
          </div>
          <div className='full-page centered top-bump-20'>
            <div className='submit_wrapper'>
              <button type="submit" onClick={onResetPassword}>
                <span>{submit}</span>
              </button>
            </div>
          </div>
          <div className="login-forgot-pw-div full-page">
            {message && <p>{message}</p>}
        </div>
        <div className="centered rightside">
          <Link className="auth-link" onClick={()=>{setShowPopup(false)}}>
            cancel
          </Link>
        </div>
      </form >
      )
    }
    else {
      return (
        <div className='landing'>
          <div className={ message? 'auth-message' : 'hidden' }>
            { message && <p>{message}</p> }
          </div>
          
          <form className='auth-form'>
            <div className={invited? "landing_wrapper invited" : "landing_wrapper"}>
            {invited && 
              <div>
                <h4>Welcome to CinqDI!</h4> 
                <h5>Please choose a password to continue setting up your account.</h5>
              </div>
              }
              <h2 className={invited? 'invited' : ''}>{heading}</h2>
            <div className='login_entry_wrapper'>
              <input
                autoFocus
                type="password"
                placeholder="New Password *"
                value={newPassword}
                onChange={(e) => handleNewPasswordChange(e)} required
              />
              <label className={newPasswordErrorStatus ? 'error show' : 'error'}>Enter password</label>
              <label className={newPasswordComplexityErrorStatus ? 'error show' : 'error'}>Please enter a valid password with a minimum of 8 characters of uppercase lowercase, and special character</label>
            </div>
            <div className='login_entry_wrapper'>
              <input
                type="password"
                placeholder="Confirm New Password *"
                value={repeatNewPassword}
                onChange={(e) => handleRepeatPasswordChange(e)} required
              />
              <label className={repeatPasswordErrorStatus ? 'error show' : 'error'}>Enter password </label>
              <label className={passwordMismatchErrorStatus ? 'error show' : 'error'}>Password mismatch</label>
            </div>
            <div className='submit_wrapper'>
              <button type="submit" onClick={onResetPassword}>
                <span>{submit}</span>
              </button>
            </div>
            <div className='forgot-div'>
              <p className='auth-link' onClick={() => { navigate('/signup') }}>CANCEL</p>
            </div>
          </div>
        </form >
      </div>
      )
    }
  }

  return (
    <div>
      {determinResetPasswordLayout(type)}
    </div>
  )
}

ResetPasswordForm.propTypes = {
  type: PropTypes.string,
  email: PropTypes.string,
  setShowPopup: PropTypes.func,
  invited: PropTypes.bool
}

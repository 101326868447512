import React from "react";
import AdminMenu from "./AdminMenu";
import Audiences from "./Audiences";
import Categories from "./Categories";
import EditUsers from "./EditUsers";
import Activations from "./Activations";
import ActivationExports from "./ActivationExports"; 
import ActivationDistro from "./ActivationDistro"; 
import Feedback from "./Feedback";
import Dev from "./Dev";
import DevTools from "./DevTools";
import Organizations from "./Organizations";
import { selectAdminPage } from "../../features/directory/directorySlice";
import { useSelector } from "react-redux";

export default function AdminDashboard() {
  
  const adminPage = useSelector(selectAdminPage);
  //const dispatch = useDispatch();

  //const { data: allAudiences } =  useGetSelectAllAudiencesQuery();
  //const { data: users } =  useUserListQuery();

  //const userRole = useSelector(selectUserRole);
  //const userOrgId = useSelector(selectOrgId);
  //const filterByOrgId = userRole == "ADMIN";

  console.log('adminPage is', adminPage);
  const whichPage = () =>  {
    switch (adminPage) {
      case "dashboard":
        return <Activations /> 
      case "categories":
        return <Categories /> 
      case "users":
        return <EditUsers />
      case "activations":
        return <Activations />
      case "exports":
        return <ActivationExports />
      case "activationDistro":
        return <ActivationDistro />
      case "feedback":
        return <Feedback />
      case "create-audience":
        return <Audiences />
      case "dev":
        return <Dev />
      case "dev-tools":
        return <DevTools />
      case "orgs":
        return <Organizations />
      default:
        return <Activations /> 
    }
  }

  //const activeUserCount = users?.filter((t) => (filterByOrgId ? t.org_id == userOrgId : true) && t.role !== 'DELETED' && t.role !== 'PENDING').length;
  //const pendingUserCount = users?.filter((t) => (filterByOrgId ? t.org_id == userOrgId : true) && t.role == 'PENDING').length;

  /* Remove Dashboard
  const basePage = 
    <div key='base' id='admin-dashboard'>
      <div className='full-page'>
        <div className='admin-stat-div'>
        <div className='admin-stat-box hidden' onClick={() => {dispatch(saveDirectory({type:'adminPage', value:'activations'}))}}>
          <h3 className=''>Activations</h3>
          <ul>
            <li>{allAudiences?.length} Pre Set</li>
            <li className='hidden'></li>
            <li className='total hidden'>12 Total</li>
          </ul>
        </div>
        </div>
        <div className='admin-stat-div'>
        <div className='admin-stat-box' onClick={() => {dispatch(saveDirectory({type:'adminPage', value:'users'}))}}>
          <h3 className=''> Users </h3>
          <ul>
            <li>{pendingUserCount} Pending </li>
            <li>{activeUserCount} Active</li>
            <li className='total'>{(pendingUserCount || 0) + (activeUserCount || 0)} Total</li>
          </ul>
        </div>
        </div>
        <div className='admin-stat-div'>
        <div className='admin-stat-box hidden'>
          <h3 className=''> Admins </h3>
          <ul>
            <li>{users?.filter(t => t.role == 'ADMIN')?.length} Active </li>
          </ul>
        </div>
        </div>
      </div>
      <div className='full-page'>
        <div className='admin-stat-div'>
        <div className='admin-stat-box hidden'>
          <h3 className=''> Approval Queue </h3>
          <ul>
            <li>3 Audience Lists</li>
            <li>2 Categories</li>
            <li className='total'>5 Total</li>
          </ul>
        </div>
        </div>
        <div className='admin-stat-div'>
        <div className='admin-stat-box hidden'>
          <h3 className=''> Notifications </h3>
          <ul>
            <li>5 Chats</li>
            <li>3 Emails</li>
            <li className='total'>8 Total</li>
          </ul>
        </div>
        </div>
        <div className='admin-stat-div'>
        <div className='admin-stat-box hidden'>
          <h3 className=''> Orders </h3>
          <ul>
            <li>23 Pending</li>
            <li>125 Completed</li>
            <li className='total'>148 Total</li>
          </ul>
        </div>
        </div>
      </div>
    </div>
    */

  return (
    <>
      <AdminMenu /> 
      <div className='admin-dashboard-body'>
        {whichPage()}
      </div>
    </>
  )
}
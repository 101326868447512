import React, { useState, useEffect } from "react";
import { useAddOrganizationMutation } from "../../app/api";

export default function NewOrganization() {

  const [organizationName, setOrganizationName] = useState('');
  const [organizationErrorMessage, setOrganizationErrorMessage] = useState('');
  const [addOrganization, { isSuccess: organizationAdded, isError: organizationError, error }] = useAddOrganizationMutation();
 
  const [freeVersion, setFreeVersion] = useState(false);

  const [singleIssueCpm, setSingleIssueCpm] = useState('$1');
  const [multiIssueCpm, setMultiIssueCpm] = useState('$1');
  const [recordCount1kTo10kPrice, setRecordCount1kTo10kPrice] = useState('$0.005');
  const [recordCount10kTo100kPrice, setRecordCount10kTo100kPrice] = useState('$0.005');
  const [recordCount100kTo1mPrice, setRecordCount100kTo1mPrice] = useState('$0.005');
  const [recordCount1mTo10mPrice, setRecordCount1mTo10mPrice] = useState('$0.005');
  const [recordCount10mTo100mPrice, setRecordCount10mTo100mPrice] = useState('$0.005');
  const [recordCount100mPlusPrice, setRecordCount100mPlusPrice] = useState('$0.005');

  const export_billing_rate = [0.005, 0.01, 0.015, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09];
  const cpms = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5];

  useEffect(() => {
    setOrganizationErrorMessage('');
    if (organizationError) {
      setOrganizationErrorMessage('Error: ' + JSON.stringify(error.data.detail.split('"')[1]));
    }
  }, [organizationError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (organizationName) {
        addOrganization({ 
          org_name: organizationName, 
          free_version: freeVersion? 1 : 0, 
          single_issue_cpm: parseFloat(singleIssueCpm.replaceAll("$", "")),  
          multi_issue_cpm: parseFloat(multiIssueCpm.replaceAll("$", "")),
          record_count_1k_to_10k_price: parseFloat(recordCount1kTo10kPrice.replaceAll("$", "")), 
          record_count_10k_to_100k_price: parseFloat(recordCount10kTo100kPrice.replaceAll("$", "")), 
          record_count_100k_to_1m_price: parseFloat(recordCount100kTo1mPrice.replaceAll("$", "")), 
          record_count_1m_to_10m_price: parseFloat(recordCount1mTo10mPrice.replaceAll("$", "")),
          record_count_10m_to_100m_price: parseFloat(recordCount10mTo100mPrice.replaceAll("$", "")),
          record_count_100m_plus_price: parseFloat(recordCount100mPlusPrice.replaceAll("$", ""))
        });
    } else {
      setOrganizationErrorMessage('Please enter a name');
    }
    setOrganizationName('');
  };

  return (
    <div className="orgs">
      <div className="user_filter_toggle">
        <input type="radio" name="toggle"  onClick={() => {setFreeVersion(0)}} id="download_user_all_toggle" />
        <label htmlFor="download_user_all_toggle" className='Init' >Self-Serve</label>
        
        <input type="radio" name="toggle" onClick={() => {setFreeVersion(1)}} id="download_user_active_toggle" />
        <label htmlFor="download_user_active_toggle">Bypass Billing</label>
      <div className={freeVersion == 'All' || freeVersion == 'Init'? 'user_toggle_selector no-border' : 'user_toggle_selector'}></div></div>   
      <form onSubmit={handleSubmit}>
        <label>Organization Name:</label>
        <input
          type="text"
          value={organizationName}
          onChange={(event) => setOrganizationName(event.target.value)}
          placeholder="Organization Name"
        />
        <div className=''>
          <div className={freeVersion? '' : 'hidden'}>
            <label>Single Issue CPM: </label>
            <select  value={singleIssueCpm || ''} onChange={() => { setSingleIssueCpm(event.target.value) }}>
              {cpms.map((i) => <option key={i}>${i}</option>)}
            </select>

            <label>Multi-Issue CPM:</label>
            <select  value={multiIssueCpm || ''} onChange={() => { setMultiIssueCpm(event.target.value) }}>
              {cpms.map((i) => <option key={i}>${i}</option>)}
            </select>
 
            <label>Export: 1,001 to 10,000</label>
            <select  value={recordCount1kTo10kPrice || ''} onChange={() => { setRecordCount1kTo10kPrice(event.target.value) }}>
              {export_billing_rate.map((i) => <option key={i}>${i}</option>)}
            </select>
   
            <label>Export: 10,001 to 100,000</label>
            <select  value={recordCount10kTo100kPrice || ''} onChange={() => { setRecordCount10kTo100kPrice(event.target.value) }}>
              {export_billing_rate.map((i) => <option key={i}>${i}</option>)}
            </select>
  
            <label>Export: 100,001 to 1 Million</label>
            <select  value={recordCount100kTo1mPrice || ''} onChange={() => { setRecordCount100kTo1mPrice(event.target.value) }}>
              {export_billing_rate.map((i) => <option key={i}>${i}</option>)}
            </select>

            <label>Export: 1M to 10M</label>
            <select  value={recordCount1mTo10mPrice || ''} onChange={() => { setRecordCount1mTo10mPrice(event.target.value) }}>
              {export_billing_rate.map((i) => <option key={i}>${i}</option>)}
            </select>

            <label>Export: 10M to 100M</label>
            <select  value={recordCount10mTo100mPrice || ''} onChange={() => { setRecordCount10mTo100mPrice(event.target.value) }}>
              {export_billing_rate.map((i) => <option key={i}>${i}</option>)}
            </select>

            <label>Export: 100M+</label>
            <select  value={recordCount100mPlusPrice || ''} onChange={() => { setRecordCount100mPlusPrice(event.target.value) }}>
              {export_billing_rate.map((i) => <option key={i}>${i}</option>)}
            </select>
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
      {organizationAdded && <p>Organization Added</p>}
      {organizationErrorMessage && <p style={{color: 'red'}}>{organizationErrorMessage}</p>}
    </div>
  )
}


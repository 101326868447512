import React, { useState, useRef, useEffect } from "react";
import { 
  useOrgDomainListQuery, 
  useAddDomainMutation, 
  useDeleteDomainMutation, 
  useSetOrganizationArchiveStatusMutation,
  //useSetOrganizationFreeVersionMutation, 
  useSetOrganizationBypassPricingMutation
} from "../../app/api";
import PropTypes from "prop-types";

export default function Organization({ organization }) {

  const [newDomain, setNewDomain] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [orgToArchive, setArchiveOrg] = useState('');
  const [orgToRestore, setRestoreOrg] = useState('');

  const { data: domainList, isLoading, refetch: refetchOrgDomains } = useOrgDomainListQuery({org_id: organization.id});
  const [addDomain, {isSuccess: domainAdded}] = useAddDomainMutation();
  const [deleteDomain, {isSuccess: domainDeleted}] = useDeleteDomainMutation();
  const [setOrganizationArchiveStatus] = useSetOrganizationArchiveStatusMutation();
  //const [setOrganizationFreeVersion] = useSetOrganizationFreeVersionMutation(); 
  const [setOrganizationBypassPricing] = useSetOrganizationBypassPricingMutation();
  const [isValidDomainName, setIsValidDomainName] = useState(true);
  const [domainErrorMessage, setDomainErrorMessage] = useState('');
  const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Bypass Values
  const [orgSingleIssueCpm, setOrgSingleIssueCpm] = useState('');
  const [orgMultiIssueCpm, setOrgMultiIssueCpm] = useState('');
  const [orgRecordCount1kTo10kPrice, setOrgRecordCount1kTo10kPrice] = useState('');
  const [orgRecordCount10kTo100kPrice, setOrgRecordCount10kTo100kPrice] = useState('');
  const [orgRecordCount100kTo1mPrice, setOrgRecordCount100kTo1mPrice] = useState('');
  const [orgRecordCount1mTo10mPrice, setOrgRecordCount1mTo10mPrice] = useState('');
  const [orgRecordCount10mTo100mPrice, setOrgRecordCount10mTo100mPrice] = useState('');
  const [orgRecordCount100mPlusPrice, setOrgRecordCount100mPlusPrice] = useState('');
  const singleIssueCpm = useRef();
  const multiIssueCpm = useRef();
  const recordCount1kTo10kPrice = useRef();
  const recordCount10kTo100kPrice = useRef();
  const recordCount100kTo1mPrice = useRef();
  const recordCount1mTo10mPrice = useRef();
  const recordCount10mTo100mPrice = useRef();
  const recordCount100mPlusPrice = useRef();

  const export_billing_rate = [0.005, 0.01, 0.015, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09];
  const cpms = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5];
  
  useEffect(() => {
    if (organization) {
      setOrgSingleIssueCpm('$' + organization.single_issue_cpm);
      setOrgMultiIssueCpm('$' + organization.multi_issue_cpm);
      setOrgRecordCount1kTo10kPrice('$' + organization.record_count_1k_to_10k_price);
      setOrgRecordCount10kTo100kPrice('$' + organization.record_count_10k_to_100k_price);
      setOrgRecordCount100kTo1mPrice('$' + organization.record_count_100k_to_1m_price);
      setOrgRecordCount1mTo10mPrice('$' + organization.record_count_1m_to_10m_price);
      setOrgRecordCount10mTo100mPrice('$' + organization.record_count_10m_to_100m_price);
      setOrgRecordCount100mPlusPrice('$' + organization.record_count_100m_plus_price);
    }
  }, [organization]);

  const toggleArchive = (org_id, show) => {
    document.getElementById('archive_' + org_id).classList.toggle('hidden', !show);
  }
  const deletePopup = () => {
    if (orgToArchive != '') {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget' style={{width: '500px'}}>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setArchiveOrg('')}}/>
            <div className='centered'>
              <h3> Achive Organization </h3>
              <p> Are you sure you want to archive {orgToArchive.name}?</p>
              <p> All users in this organization will be prevented from logging in</p>
              <button onClick={()=>{archiveOrganization(orgToArchive.id); setArchiveOrg('')}}> Archive </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const restorePopup = () => {
    if (orgToRestore != '') {
      return (
        <div className='popup_overlay expanded'>
          <div className='popup_widget' style={{width: '500px'}}>
            <button id='fa-times' className='fa fa-times close' onClick={()=>{setRestoreOrg('')}}/>
            <div className='centered'>
              <h3> Restore Organization </h3>
              <p> Are you sure you want to restore {orgToRestore.name}?</p>
              <button onClick={()=>{restoreOrganization(orgToRestore.id); setRestoreOrg('')}}> Restore </button>
            </div>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    refetchOrgDomains();
  }, [domainAdded, domainDeleted]);

  async function archiveOrganization(org_id) {  
    try {
      await setOrganizationArchiveStatus({ org_id: org_id, org_status: 0 }).unwrap();
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  }

  async function restoreOrganization(org_id) {  
    try {
      await setOrganizationArchiveStatus({ org_id: org_id, org_status: 1 }).unwrap();
    } catch (error) {
      console.error('Error restoring organization:', error);
    }
  }
  
  async function handleAddDomain() {
    setIsValidDomainName(true);
    setDomainErrorMessage('');
    const sanitizedValue = newDomain.replace(/@/g, '');

    if (!sanitizedValue.includes('@') && domainRegex.test(sanitizedValue)) {
      setIsValidDomainName(true);
      try {
        await addDomain({ org_id: organization.id, domain: sanitizedValue }).unwrap();
        setNewDomain('');
      } catch (error) {
        var errorMessage = error.data.detail.match(/(?<=").+?(?=")/)[0];
        setDomainErrorMessage(`Error: ${errorMessage}`);
      }
    } else {
      setIsValidDomainName(false);
    }
  };

  async function bypassValueChangeHandler(ord_id, value_name, value) {
    switch(value_name) {
      case 'single_issue_cpm':
        setOrgSingleIssueCpm(value);
      break;
      case 'multi_issue_cpm':
        setOrgMultiIssueCpm(value);
      break;
      case 'record_count_1k_to_10k_price':
        setOrgRecordCount1kTo10kPrice(value);
      break;
      case 'record_count_10k_to_100k_price':
        setOrgRecordCount10kTo100kPrice(value);
      break;
      case 'record_count_100k_to_1m_price':
        setOrgRecordCount100kTo1mPrice(value);
      break;
      case 'record_count_1m_to_10m_price':
        setOrgRecordCount1mTo10mPrice(value);    
      break;
      case'record_count_10m_to_100m_price':
        setOrgRecordCount10mTo100mPrice(value);
      break;
      case'record_count_100m_plus_price':
        setOrgRecordCount100mPlusPrice(value);
      break;
    }

    try { 
      await setOrganizationBypassPricing({
        org_id: ord_id,
        single_issue_cpm: parseFloat(singleIssueCpm.current.value.replaceAll("$", "")),
        multi_issue_cpm: parseFloat(multiIssueCpm.current.value.replaceAll("$", "")),
        record_count_1k_to_10k_price: parseFloat(recordCount1kTo10kPrice.current.value.replaceAll("$", "")),
        record_count_10k_to_100k_price: parseFloat(recordCount10kTo100kPrice.current.value.replaceAll("$", "")), 
        record_count_100k_to_1m_price: parseFloat(recordCount100kTo1mPrice.current.value.replaceAll("$", "")),
        record_count_1m_to_10m_price: parseFloat(recordCount1mTo10mPrice.current.value.replaceAll("$", "")),
        record_count_10m_to_100m_price: parseFloat(recordCount10mTo100mPrice.current.value.replaceAll("$", "")),
        record_count_100m_plus_price: parseFloat(recordCount100mPlusPrice.current.value.replaceAll("$", ""))
    })
  } 
  catch (error) {
    console.error('Error setting bypass values for an organization', error);
  }
 }
 
  const handleRemoveDomain = (domainId) => {
    deleteDomain({ domain_id: domainId }, {
      onSuccess: () => {
        refetchOrgDomains();
      },
      onError: (error) => {
        console.error('Error removing domain:', error);
      },
    });
  };

  /* Removed dynamic changing of bypass.

    async function setOrganizationFreeVersionStatus(org_id, free_version) {  
    console.log('Org_id: ' + org_id + '. Free Version: ' + free_version);
    let valueChange = 0;
    if (free_version == 0) {
      valueChange = 1;
    }
    try {
      await setOrganizationFreeVersion({ org_id: org_id, free_version: valueChange }).unwrap();
    } catch (error) {
      console.error('Error setting Free Version for organization:', error);
    }
  } */

  return (
    <div className="user-list-display-organization">
      {deletePopup()}
      {restorePopup()}
      <div>
        <div className='organization-row'
          onMouseEnter={() => toggleArchive(organization.id, true)}
          onMouseLeave={() => toggleArchive(organization.id, false)}>
          <div className='organization-header-wrapper'>
            <h2>
              {organization.name}
            </h2>
            {organization.org_status == 1 ? 
              <i
                className='fa-solid fa-archive hover-point hidden'
                id={`archive_${organization.id}`}
                title='Archive Organization'
                style={{ fontSize: '18px' }}
                onClick={()=>{setArchiveOrg(organization)}}
              ></i>
            : 
              <i
                className='fa-solid fa-upload hover-point hidden'
                id={`archive_${organization.id}`}
                title='Restore Organization'
                style={{ fontSize: '18px' }}
                onClick={()=>{setRestoreOrg(organization)}}
              ></i>
            }
          </div>

          {organization.org_status == 1 ? 
            <ul className={organization.free_version == 1 ? '' : 'hidden'}>
              <li>
                <label>Subscription Type</label>
                <p>{organization.free_version? 'Bypass Billing' : 'Self-Serve'}</p>
             </li>
              <li>
                <label>Single Issue CPM</label>
                <select ref={singleIssueCpm} onChange = {()=>{setOrgSingleIssueCpm(singleIssueCpm.current.value); bypassValueChangeHandler(organization.id, 'single_issue_cpm', singleIssueCpm.current.value)}} value={orgSingleIssueCpm}>
                  {cpms.map((i) => <option key={i}>${i}</option>)}  
                </select>
              </li>
              <li>
                <label>Multi Issue Cpm</label>  
                <select ref={multiIssueCpm} onChange = {()=>{setOrgMultiIssueCpm(multiIssueCpm.current.value); bypassValueChangeHandler(organization.id, 'multi_issue_cpm', multiIssueCpm.current.value)}} value={orgMultiIssueCpm}>
                  {cpms.map((i) => <option key={i}>${i}</option>)}  
                </select>    
              </li>
              <li>
                <label>Record Count 1k to 10k price</label>
                <select ref={recordCount1kTo10kPrice} onChange = {()=>{setOrgRecordCount1kTo10kPrice(recordCount1kTo10kPrice.current.value); bypassValueChangeHandler(organization.id, 'record_count_1k_to_10k_price', recordCount1kTo10kPrice.current.value)}} value={orgRecordCount1kTo10kPrice}>
                  {export_billing_rate.map((i) => <option key={i}>${i}</option>)}  
                </select>
              </li>
              <li>
                <label>Record Count 10k to 100k Price</label>  
                <select ref={recordCount10kTo100kPrice} onChange = {()=>{setOrgRecordCount10kTo100kPrice(recordCount10kTo100kPrice.current.value); bypassValueChangeHandler(organization.id, 'record_count_10k_to_100k_price', recordCount10kTo100kPrice.current.value)}} value={orgRecordCount10kTo100kPrice}>
                  {export_billing_rate.map((i) => <option key={i}>${i}</option>)}  
                </select>
              </li>
              <li>
                <label>Record Count 100k to 1m Price</label>   
                <select ref={recordCount100kTo1mPrice} onChange = {()=>{setOrgRecordCount100kTo1mPrice(recordCount100kTo1mPrice.current.value); bypassValueChangeHandler(organization.id, 'record_count_100k_to_1m_price', recordCount100kTo1mPrice.current.value)}} value={orgRecordCount100kTo1mPrice}>
                  {export_billing_rate.map((i) => <option key={i}>${i}</option>)}  
                </select> 
              </li>
              <li>
                <label>Record Count 1m to 10m Price</label>   
                <select ref={recordCount1mTo10mPrice} onChange = {()=>{setOrgRecordCount1mTo10mPrice(recordCount1mTo10mPrice.current.value); bypassValueChangeHandler(organization.id, 'record_count_1m_to_10m_price', recordCount1mTo10mPrice.current.value)}} value={orgRecordCount1mTo10mPrice}>
                  {export_billing_rate.map((i) => <option key={i}>${i}</option>)}  
                </select>  
              </li>
              <li>
              <label>Record Count 10m to 100m Price</label>   
                <select ref={recordCount10mTo100mPrice} onChange = {()=>{setOrgRecordCount10mTo100mPrice(recordCount10mTo100mPrice.current.value); bypassValueChangeHandler(organization.id, 'record_count_10m_to_100m_price', recordCount10mTo100mPrice.current.value)}} value={orgRecordCount10mTo100mPrice}>
                  {export_billing_rate.map((i) => <option key={i}>${i}</option>)}  
                </select>
              </li>
              <li>
                <label>Record Count 100m Plus Price</label>   
                <select ref={recordCount100mPlusPrice} onChange = {()=>{setOrgRecordCount100mPlusPrice(recordCount100mPlusPrice.current.value); bypassValueChangeHandler(organization.id, 'record_count_100m_plus_price', recordCount100mPlusPrice.current.value)}} value={orgRecordCount100mPlusPrice}>
                  {export_billing_rate.map((i) => <option key={i}>${i}</option>)}  
                </select>
              </li>
            </ul>
            : 
            <></>          
          }
          {organization.org_status == 1 &&
            <ul className={organization.free_version == 0 ? '' : 'hidden'}>
              <li>
                <label>Subscription Type</label>
                <p>{organization.free_version? 'Bypass Billing' : 'Self-Serve'}</p>
              </li>
            </ul>
          }
        </div>
        {organization.org_status == 1 &&
          <div onClick={() => setIsExpanded(!isExpanded)} style={{width: 'unset'}} className="hover-point">
            {isExpanded ? `-  Domains (${domainList?.length || '0'})` : `+ Domains (${domainList?.length || '0'})`}
          </div>
        }
        
      </div>
      {isExpanded && (
        <div>
          {isLoading ? (
            <p>Loading domains...</p>
          ) : (
            <>
            <div className="table" style={{marginLeft: '30px', marginTop: '10px'}}>
              {(domainList && domainList.length > 0) &&
                domainList.map(domain => (
                  <div className="row" key={domain.id}>
                    <div className="cell">
                      @{domain.domain}
                    </div>
                    <div className="right-cell">
                      <i className='fa-solid fa-trash hover-point' title='Delete' style={{ fontSize: '13px' }} onClick={() => handleRemoveDomain(domain.id)}></i>
                    </div>
                  </div>
                ))
              }
            </div>

            <div className="table" style={{marginLeft: '30px'}}>
              <div className="row" style={{display: "flex", alignItems: "center"}}>
                <div className="cell" style={{width: '240px'}}>
                  <span>@</span>
                  <input
                    type="text"
                    value={newDomain}
                    onChange={(e) => setNewDomain(e.target.value)}
                    placeholder="Add new domain"
                  />
                </div>
                <div className="cell">
                  <i className='fa-solid fa-square-plus hover-point' style={{marginRight: '5px'}} onClick={() => handleAddDomain()}></i>
                </div>
                <div className="cell domain-error">
                  {domainErrorMessage}
                  {!isValidDomainName && 'Invalid domain name'}
                </div>
              </div>
            </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

Organization.propTypes = {
  organization: PropTypes.object
}
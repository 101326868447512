import React, {useState, useRef, useEffect} from "react";
import { useSelector } from "react-redux";
import { liveRampPlatforms } from "../../data/ActivateData";
import { useNavigate } from "react-router-dom";
import { createSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { isPermissionsRO } from "../../features/auth/authSlice";
import { isPermissionsAA } from "../../features/auth/authSlice";
import { numberWithCommas } from "../userSettings";

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default function ActivatedStepOne({setActivePlatform, activationJSON, thisAudience, selectedStep, existingMultiFormats, origAudience, orgData}) {

  const hasROpermissions = useSelector(isPermissionsRO);
  const hasAApermissions = useSelector(isPermissionsAA);

  const [selectedPlatform, setSelectedPlatform] = useState(null);

  const [activateView, setActivateView] = useState('platform');

  const todaysDateString = new Date().toISOString().slice(0, 10); 
  const nextDateString = addDays(new Date(), 7).toISOString().slice(0, 10); 

  const startDate = useRef();
  const endDate = useRef();
  const accountId = useRef();
  const platformType = useRef();
  const exportType = useRef();

  const platformRadio = useRef();
  const exportRadio = useRef();

  var eInit = true;
  var sInit = true;

  const navigate = useNavigate();

  const hasThisFormatRecord = (selectedExportIdentifier) => {  
    const yes = existingMultiFormats?.find(t => t.format == selectedExportIdentifier);
    if (yes) {
      return true;
    }
    else {
      return false;
    }
  }

  
  useEffect(() => {
    if (activationJSON?.activationType){
      console.log('settingPlatform from preset activationJSON', activationJSON);
      if (activationJSON.activationType == 'export'){
        setActivateView('export');
        exportType.current.value = activationJSON.activationID;
      }
      else {
        setActivateView('platform');
        platformType.current.value = activationJSON.activationID;
      }
      setSelectedPlatform(activationJSON.activationID);
    }
    if (activationJSON?.activationAccountID){
      accountId.current.value = activationJSON.activationAccountID;
    }
    if (activationJSON?.activationStartDate){
      startDate.current.value = activationJSON.activationStartDate;
    }
    if (activationJSON?.activationEndDate){
      endDate.current.value = activationJSON.activationEndDate;
    }
  },[])


  const downloadTypes = [{ value: 'ids', label: 'LALID'}, 
                         { value: 'facebook', label: 'Facebook'}, 
                         { value: 'walk', label: 'Walk List'},
                         { value: 'phone', label: 'Phone List'}, 
                         { value: 'mail', label: 'Mail'}];

  const exportSelect = () => {
    return (
      <select name='exports' ref={exportType} className='activation-select' onChange={(e)=>{clearError(exportType);setSelectedPlatform(e.target.value);}}>
        <option>Select an Export Type </option>
        {downloadTypes
          .map((h,i) => (
            <option key={i} value={h.value} disabled={hasThisFormatRecord(h.value) ? 'disabled' : ''}>{h.label}</option>
          ))
        }
      </select>
    )
  }

  const platformSelect = () => {
    return (
      <select name='platforms' ref={platformType} className='activation-select' onChange={(e)=>{clearError(platformType); setSelectedPlatform(e.target.value);}}>
        <option>Select a Platform</option>
        {liveRampPlatforms
          .filter(t => t.active == 1)
          .sort((a, b) => a.text.localeCompare(b.text))
          .map((h, i) => (
            <option key={i} value={h.id}>{h.text}</option>
          ))
        }
      </select>
    )
  }

  

  const activationForm = () => {
      return (
      <div className='container p-1 sides-only'>
        <div className='row'>
          <div className='col'>
            <h3>Choose a platform or data download</h3>
            <p>* required field</p>
          </div>
        </div>
        <hr className='w-100'/>
        <div className='row'>
          <div className="form-check">
            <input ref={platformRadio} onChange={()=>{setSelectedPlatform(null); setActivateView('platform');}}  className="form-check-input activation inline" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={activateView == 'platform' ? "checked" : ""}/>
            <label className="form-check-label activation inline" htmlFor="flexRadioDefault1">
              Platform activation 
            </label>
          </div>
          <div className="form-check">
            <input ref={exportRadio} onChange={() => {setSelectedPlatform(null); platformType.current.value = 'Select a Platform'; setActivateView('export')}} className="form-check-input activation inline" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={activateView == 'export' ? "checked" : ""} />
            <label className="form-check-label activation inline" htmlFor="flexRadioDefault2">
              Data download
            </label>
          </div>
        </div>
        <hr className='w-100'/>
          <div className={activateView == 'platform' ? 'row ' : 'row hidden'}>
            <div className='leftside'>
              <table>
                <tbody>
                  <tr>
                    <td>* Select platform</td>
                    <td colSpan={3}>{platformSelect()}</td>
                  </tr>
                  <tr>
                    <td>* Account ID</td>
                    <td colSpan={3} ><input onChange={()=>{clearError(accountId)}} ref={accountId} className='activation-input'></input></td>
                  </tr>
                  <tr>
                    <td>* Campaign Date Range</td>
                    <td>
                      <div className='field_value_input'>
                        <input ref={startDate} type='text'  placeholder='Start Date' className='date-range inline' 
                        onFocus={(e)=>{if(sInit) {clearError(startDate); e.target.type='date'; e.target.value = todaysDateString; sInit=false;}}} ></input>
                      </div>
                    </td>
                    <td>
                        <p className='mid-range activation-to'>&nbsp;to</p>
                    </td>
                    <td>
                      <div className='field_value_input'>
                        <input ref={endDate} type='text' placeholder='End Date' className='date-range inline' 
                        onFocus={(e)=>{if(eInit) {clearError(endDate); e.target.type='date'; e.target.value = nextDateString; eInit=false;}}} ></input>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='rightside'>
              <div className='rightside mr-4'>
                <div className='leftside'>
                  {platformImage(liveRampPlatforms, selectedPlatform, true, thisAudience, origAudience)}
                </div>
              </div>
            </div>
          </div>
          <div className={activateView == 'export' ? 'mt-1' : 'mt-1 hidden'}> 
            {exportView()}
          </div>
        <hr className='w-100'/>
        {platformImages(liveRampPlatforms)}
      </div>)
  }

  const exportView = () => {
    return (
        <div className='row'>
          <div className='leftside mb-0'>
            <table>
              <tbody>
                <tr>
                  <td >* Select type</td>
                  <td colSpan={3}>{exportSelect()}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='rightside'>
            <div className='rightside mr-4'>
              <div className='leftside'>
                <p> </p>
              </div>
            </div>
          </div>
        </div>
    )
  }

  const clearError = (t) => {
    t.current.className = t.current.className.replace(' error','');
  }

  const submitInfo = () => {
    // setActivePlatform = (type, platformId, platformDesc, refId, startDate, endDate) 
    if (activateView == 'platform') {
      if (platformType.current.value == 'Select a Platform' || !accountId.current.value || !startDate.current.value || !endDate.current.value) {
        if (platformType.current.value == 'Select a Platform'){
          platformType.current.className = platformType.current.className + ' error';
        }
        if (!accountId.current.value){
          accountId.current.className = accountId.current.className + ' error';
        }
        if (!startDate.current.value){
          startDate.current.className = startDate.current.className + ' error';
        }
        if (!endDate.current.value){
          endDate.current.className = endDate.current.className + ' error';
        }
      }
      else {
        console.log('settingPlatform', selectedPlatform);
        setActivePlatform(activateView, selectedPlatform, liveRampPlatforms.find(l => l.id == selectedPlatform)?.text, accountId.current.value, startDate.current.value, endDate.current.value);
      }
    }
    else {
      if (exportType.current.value == 'Select an Export Type') {
        exportType.current.className = exportType.current.className + ' error';
      }
      else {
        setActivePlatform(activateView, selectedPlatform, downloadTypes.find(d => d.value == selectedPlatform)?.label, 'n/a', 'n/a', 'n/a');
      }
    }
  }

  return (
    <div className={selectedStep == 1 ? '' : 'hidden'}>
      {activationForm()}
      {platformBilling(liveRampPlatforms, selectedPlatform, true, thisAudience, origAudience, orgData)}
      <div className='activation-footer'>
        <div className='leftside activation-cancel-button'
         onClick={() => {navigate({ pathname: '/dashboard/audience', search: createSearchParams({ uuid: thisAudience.uuid, cView: thisAudience.cluster, custom: thisAudience.custom }).toString() })}}
        >CANCEL</div> 
        {(!hasROpermissions || hasAApermissions) && <div className='rightside activation-continue-button mr-2' onClick={()=>{submitInfo()}}>CONTINUE</div> }
      </div>
    </div>
  )
}

ActivatedStepOne.propTypes = {
  setActivePlatform: PropTypes.func,
  activationJSON: PropTypes.object,
  thisAudience: PropTypes.object,
  origAudience: PropTypes.object,
  selectedStep: PropTypes.number,
  existingMultiFormats: PropTypes.array,
  orgData: PropTypes.object
}

export const platformImages = (liveRampPlatforms) => {
    /* Pre-loading images to cache - keep hidden */
    return (
      <div className='hidden'>
        {liveRampPlatforms
          .filter(t => t.active == 0)
          .sort((a, b) => a.text.localeCompare(b.text))
          .map((h, i) => (
            <img key={i} src={"../../images/activation/"+h.image}/>
          ))
        }
      </div>
    )
  }

  export const platformImage = (liveRampPlatforms, selectedPlatform) => {
    const thisPlatform = liveRampPlatforms.find(t => t.id == selectedPlatform);
    if (!thisPlatform){
      return (<></>)
    }
    else {
      return (
        <div className="activation_image_wrapper">
          <img className='activation-image' src={"../../images/activation/"+thisPlatform.image}/>
        </div>
      )
    }
  }

  export const platformBilling = (liveRampPlatforms, selectedPlatform, someBool, thisAudience, origAudience, orgData) => {
    const thisPlatform = liveRampPlatforms.find(t => t.id == selectedPlatform);
    if (!thisPlatform){
      return (<></>)
    }
    else {
      if (orgData?.free_version) {
        if (origAudience?.audience_category == "MULTI-ISSUE"){
          return (
            <>
              <p className='billing-price-platform'>* Billing Rate: ${numberWithCommas(orgData?.multi_issue_cpm)} CPM</p>
            </>
          )
        }
        else {
          return (
            <>
              <p className='billing-price-platform'>* Billing Rate: ${numberWithCommas(orgData?.single_issue_cpm)} CPM</p>
            </>
          )
        }
      }
      else {
        return (
          <>
            <p className='billing-price-platform'>* Billing Rate: ${numberWithCommas(origAudience?.audience_cpm || thisAudience?.audience_cpm)} CPM</p>
          </>
        )
      }
    }
  }

import React, {useState, useEffect} from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserId , selectUserName, selectOrgNameString } from "../../features/auth/authSlice";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import PropTypes from "prop-types";
import { useRunCreatePaymentMutation, useGetBTClientTokenMutation } from "../../app/api";
import dropin from "braintree-web-drop-in";
import { liveRampPlatforms } from "../../data/ActivateData";
import { createSearchParams } from "react-router-dom";
import ActivatedSummary from "./ActivatedSummary";

import { 
  useAddPlatformActivationMutation, 
  useSendEmailMutation, 
  useAddUserPlatformMutation, 
  useSendAudienceActivationEmailMutation,
  useSendAudienceActivationBypassEmailMutation,
  useAddUserAudienceMutation,
  useAddAudienceMutation,
  useAddSalesMutation,
  useGetDoesUserHaveThisAudienceQuery,
  useSendStandaloneExportEmailMutation,
  useUpdateUserAudienceNoteMutation,
} from "../../app/api";

import { useRunStartAudienceBuildMutation, useGetConfigurableValueQuery } from "../../app/api";
import {generateUnique} from "../DestinationAccounts/LiveRamp";

import { numberWithCommas } from "../userSettings";

export default function ActivatedStepThree({activationJSON, thisAudience, thisUserAudience, setSelectedStep, selectedStep, existingMultiFormats, thisOverrideUserAudience, orgData}) {
  const selectedExportIdentifier = activationJSON.activationID; // for export only

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);
  const [buy, setBuy] = useState(true);

  const [showSave, setShowSave] = useState(false);
  const [freeOrganizationSave, setFreeOrganizationSave ] = useState(false);

  const [newPlatformAId, setNewPlatformAId] = useState(null);
  const [newUserAudienceRefId, setNewUserAudienceRefId] = useState(0);

  const userId = useSelector(selectUserId);
  const username = useSelector(selectUserName);
  const org_name = useSelector(selectOrgNameString);
  const currentFilter = useSelector(selectWidgetFilters);

  const noteRef = useRef();
  const accountNameRef = useRef();

  // payment calls
  const [runCreatePayment] = useRunCreatePaymentMutation();
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const [ getClientToken] = useGetBTClientTokenMutation();
  const [btClientToken, setBtClientToken] = useState(null);
  const [btCustId, setBtCustId] = useState(null);
  const [addSales] = useAddSalesMutation();

  // new audience calls
  const [addUserAudience] = useAddUserAudienceMutation();
  const [addAudience] = useAddAudienceMutation();
  const [updateUserAudienceNote] = useUpdateUserAudienceNoteMutation();

  // activation calls
  const [addPlatform] = useAddUserPlatformMutation();
  const [addPlatformActivation,{isSuccess: navPage}]  = useAddPlatformActivationMutation();
  const [sendEmail] = useSendEmailMutation();
  const [sendAudienceActivationBypassEmail] = useSendAudienceActivationBypassEmailMutation();
  const [sendAudienceActivationEmail] = useSendAudienceActivationEmailMutation();
  const [sendExportEmail] = useSendStandaloneExportEmailMutation();
  const {data: newActivationEmailRecipient} = useGetConfigurableValueQuery({configurable_name: 'email.new_activation.recipient'});
  const [runAudienceBuild] = useRunStartAudienceBuildMutation(); 

  // export calls
  const thisFormatRecord =  existingMultiFormats?.find(t => t.format == selectedExportIdentifier);


  const {data: existingPlatformUserAudience} = useGetDoesUserHaveThisAudienceQuery({audience_id: thisAudience?.id, user_id: userId}, {skip: !thisAudience});
  const {data: existingExportUserAudience} = useGetDoesUserHaveThisAudienceQuery({audience_id: thisFormatRecord?.id, user_id: userId}, {skip: !thisFormatRecord});


  const navigate = useNavigate();

  useEffect(() => {
    if (navPage) {
      console.log('done');  // callback here
    }
  }, [navPage]);

  const waitForToken = async () => {
      const newBtClientToken = await getClientToken({user_id: userId});
      console.log("look what I got", newBtClientToken.data);
      setBtClientToken(newBtClientToken.data.client_token);
      setBtCustId(newBtClientToken.data.bt_cust_id);
  }

  useEffect(() => {
    setError(null)
    setSuccess(null)
  }, [buy])

  useEffect(() => {
    if ( selectedStep == 3 && orgData?.free_version && !freeOrganizationSave) {
      // Run bypass activation
      setFreeOrganizationSave(true);
      setSuccess(true);
      runPayment();
    }
  }, [selectedStep]);

  useEffect(() => {
    if (orgData && !orgData?.free_version && userId) {
      waitForToken();
    }
  }, [userId, orgData])

  useEffect(() => {
        if (orgData && !orgData?.free_version && btClientToken) {
          console.log('setting dropin for token', btClientToken);
            const initializeBraintree = () => dropin.create({
                // insert your tokenization key or client token here
                authorization: btClientToken , 
                container: '#braintree-drop-in-div',
                dataCollector: true,
            }, function (error, instance) {
                if (error)
                    console.error(error)
                else
                    setBraintreeInstance(instance);
            });

            if (braintreeInstance) {
                setBuy(false);
                braintreeInstance
                    .teardown()
                    .then(() => {
                        initializeBraintree();
                    });
            } else {
                initializeBraintree();
            }
        }
    }, [btClientToken])

  const clearBraintree = () => {
    if (braintreeInstance) {
      setBuy(false);
      braintreeInstance
     .teardown();
    }
    setError(false);
    setBtClientToken(null);
    setBraintreeInstance(undefined);
    setSuccess(false);
    waitForToken();

  }

  const runPayment = () => {
    if (orgData && !orgData?.free_version) {
      if (braintreeInstance) {
        braintreeInstance.requestPaymentMethod(
          (error, payload) => {
            if (error) {
              console.error(error);
            } else {
              runPaymentNow(payload);
            }
        });
      }
    }
    else {
      processSuccess(0);
    }
  }

  const runPaymentNow = async (payload) => {
    setLoading('Loading...');
    const finalCost = parseFloat(activationJSON.activationCost).toFixed(2)
    const payResult = await runCreatePayment({nonce: payload.nonce, device_data: payload.deviceData , user_id: userId, bt_cust_id: btCustId, amount: finalCost});
    onPaymentCompleted(payResult.data);
  }

  const onPaymentCompleted = (response) => {
    setLoading('Loading...');
    if (response.result == 'SUCCESS') {
      // do successful things
      setLoading(null)
      setSuccess(true)
      processSuccess(response.id);
    }
    else {
      setError(response.result)      
      setLoading(null)
    }
  }

  const processSuccess = async (id) => {

    const newReferenceId = generateUnique();
    const selectedExportIdentifier = activationJSON.activationID;
    const currentRecords = thisAudience.count || thisUserAudience.records_uploaded || thisOverrideUserAudience.records_uploaded;
    const requestTypeName = activationJSON.activationType == 'platform' ?
      thisAudience.custom == 0 ? 'DISTRIBUTION' : 'CUSTOM'
        : 'MULTIFORMAT';  // distro = baseline, custom = filtered, multiformat = export

    let newUserAudienceReferenceId = 0;
    let newPlatformActivationId = 0;


    // Exports require an additional record in the audience table to request the same audience in different formats.  This adds a step to check for if s3 path exists yet here.

    if (activationJSON.activationType == 'platform') { /// Platform activation workflow
      let u = { ...thisUserAudience};
      let overrideForAdminActivations = false;

      if (JSON.stringify(thisOverrideUserAudience) != '{}'){
        /* You are activating a platform using a different user's userAudience record. 
           Check if the current session admin has their own userAudience record associated to
           the same audience. If so, use it. If not, create a new userAudience for the admin. 
        */ 
        if (JSON.stringify(u) == '{}') {
          // overrideForAdminActivations = true will generate a new userAudience
          overrideForAdminActivations = true; 
          u = { ...thisOverrideUserAudience};;
          u['user_id'] = userId;
        }
      }
      
      if (existingPlatformUserAudience && !overrideForAdminActivations) { // has audience, maybe adding additional platform
      }
      else { // must add to user audiences 
        const userActResult = await addUserAudience({user_id: userId, audience_id: thisAudience.id, reference_id: newReferenceId, title: thisAudience.title, records_uploaded: currentRecords , request_type: requestTypeName });
        u = userActResult.data;
        console.log('audience was added', userActResult?.data);
      }

      const newPlatformId = await setActivation(u); // this handles platform records 
      newPlatformActivationId = newPlatformId;
      const salesResult = await addSales({user_id: userId, type: requestTypeName, cost: activationJSON.activationBillingRate, amount: currentRecords, total: activationJSON.activationCost, reference_id: newReferenceId, payment_id: id, platform_activation_id: newPlatformActivationId || 0 , user_audience_reference_id: newUserAudienceReferenceId || 0 });
      console.log(salesResult);
      if (newPlatformId) {
        setSelectedStep(4);
        setSuccess(true);
      }
      else {
        setError('error');
      }

    } // end of Platform activation workflow

    else { /// Export activation workflow
      let userResult =  undefined;
      let userResult1 = undefined;
      if (thisFormatRecord) { /// someone exported this before
        // audience exists but does user audience?  Must check for specific download type
        if (existingExportUserAudience) {
          console.log('user has record already - ?', thisFormatRecord);
          newUserAudienceReferenceId = thisFormatRecord.reference_id;
        }
        else {
          userResult1 = await addUserAudience({user_id: userId, audience_id: thisFormatRecord.id, reference_id: newReferenceId, title: thisAudience.title + ' - ' + selectedExportIdentifier, records_uploaded: currentRecords , request_type: 'MULTIFORMAT' });
          console.log('user record added 1', userResult1.data.reference_id)
          newUserAudienceReferenceId = userResult1.data.reference_id;
        }
         
        // Removed s3 check to always send admin export email
        sendExportEmail({uuid: thisFormatRecord.uuid, username: username, org_name: org_name}); // READY EMAIL
        if (thisFormatRecord.s3_path) { // if someone exported this before, this is most likely true
          //sendExportEmail({uuid: thisFormatRecord.uuid, username: username, org_name: org_name}); // READY EMAIL
        }
        else { // if no s3_path for some reason, request creation of one
          try {
            runAudienceBuild({filter: thisFormatRecord.filter_query, audience: thisFormatRecord.audience, unique_id: thisFormatRecord.uuid, format: selectedExportIdentifier })
          }
          catch(e) {
            console.log('there was an error running audience build', e);
          }
        }
        setNewUserAudienceRefId(newUserAudienceReferenceId);
      }

      else { /// we must add replica of audience here
         const {data: result}= await addAudience({title: thisAudience.title + ' - ' + selectedExportIdentifier , description: thisAudience.description || thisUserAudience.description || 'multi-format', active: 0, custom: 2, audience: thisAudience.audience, filter_query: thisAudience.filter_query, job_status: 'multFormat', base_audience_id: thisAudience.id, base_ga_code: thisAudience.ga_code, uuid: newReferenceId, orig_uuid: thisAudience.uuid, orig_custom: thisAudience.custom, format: selectedExportIdentifier, custom_user_id: userId });

         // we know user audience can't exist because audience didn't exist - so leave hasUserRecord false
        // add user record
        userResult = await addUserAudience({user_id: userId, audience_id: result.audience_id, reference_id: newReferenceId, title: thisAudience.title + ' - ' + selectedExportIdentifier, records_uploaded: currentRecords , request_type: 'MULTIFORMAT' });
        console.log("user record added 2", userResult.data.reference_id);
        newUserAudienceReferenceId = userResult.data.reference_id;

        // request s3_path of new audience we requested
        // in this workflow, the ready email will be triggered by the update of the s3_path
        try {
          runAudienceBuild({filter: thisAudience.filter_query, audience: thisAudience.audience, unique_id: newReferenceId, format: selectedExportIdentifier })
        }
        catch(e) {
          console.log('there was an error running audience build', e);
        }
        setNewUserAudienceRefId(newUserAudienceReferenceId);

      }
      const salesResult = await addSales({user_id: userId, type: requestTypeName, cost: activationJSON.activationBillingRate, amount: currentRecords, total: activationJSON.activationCost, reference_id: newReferenceId, payment_id: id, platform_activation_id: newPlatformActivationId || 0, user_audience_reference_id: newUserAudienceReferenceId || 0});
      console.log(salesResult);
      if (userResult1 || userResult) {
        setSelectedStep(4);
        setSuccess(true);
      }
    }  // End of export workflow

  }

  const bt = 
    <div className = {buy ? "" : ""}>
      {success && 
        <>
          <div className='alert alert-success'>
          </div>
        </>
      }
      {error && 
        <div className='alert alert-danger w-100 p-0'>
          {error} - Please try again 
        </div>
      }
      <div className={success ? 'hidden':''}>
        <div id={"braintree-drop-in-div"} />
      </div>
      {loading && <p className='w-100 '>Processing... Please wait...</p>}
  </div>


  const setActivation = async (u) => {
    console.log('thisUserAudience', thisUserAudience);
    console.log('userActResult', u);
    console.log('activationJSON', activationJSON);
   
    const thisPlatform = liveRampPlatforms.find(t => t.id == activationJSON.activationID);
      // TODO: do not need to add if not new
      // The intention of user_platforms is to save the user's accountIds associated with each platform so we can let them choose from an existing list when activating
      const userPlatformId = await addPlatform({user_id: userId, platform_id: thisPlatform.id, platform_desc: thisPlatform.text, account_id: activationJSON.activationAccountID});

      const contactEmailConst = activationJSON.activationContactEmailAddress; 
      const platformAccountIdConst = activationJSON.activationAccountID; 
      const uniqueIDConst = generateUnique();

      // add platform activation details 
      // TODO: might be helpful to add user_id here 
      const platformAdded = await addPlatformActivation(
        { reference_id: u.reference_id,
          audience_id: thisAudience?.id,
          user_platform_id: userPlatformId.data.user_platform_id,
          platform_id_identify:  0, 
          cookies_identify: 0,
          idfa_identify: 0,
          aaid_identify: 0,
          unique_id: uniqueIDConst, 
          client_name: '', 
          usage_agree: activationJSON.activationAuthTimestamp,
          payment_model_agree: activationJSON.activationAuthTimestamp, 
          payment_requirement_agree: activationJSON.activationAuthTimestamp,  
          contact_email: contactEmailConst,
          start_date: activationJSON.activationStartDate,
          end_date: activationJSON.activationEndDate,
          planned_usage: 'n/a',
          billing_rate: parseFloat(activationJSON.activationBillingRate),
          billing_name: activationJSON.activationBillingContactName,
          billing_email: activationJSON.activationBillingEmailAddress,
        });
        console.log('platformAdded result', platformAdded);
      if (platformAdded.data.result == "SUCCESS") {
        console.log('platform activation added.  id is:' ,platformAdded.data.id); 
        setNewPlatformAId(platformAdded.data.id);
      }
      else {
        console.log('error adding platform');
      }

      // Removed s3 check to always send admin activation email
      if (orgData.free_version) {
        sendAudienceActivationBypassEmail({uuid: thisAudience.uuid, org_name: org_name});
      }
      else {
        sendAudienceActivationEmail({uuid: thisAudience.uuid});
      }

      // If audience not in DataLake, we must request Datalake to build
      if (thisAudience.s3_path) {
        // Audience has already been built before, send an email with platform activation info
        // Do not need to wait to send activation request
        console.log('not building audience, already have file', thisAudience.s3_path);
        sendEmail({  // also send to customer that we got their submission
            template_name: 'new_activation',
            recipient_to: contactEmailConst,
            recipient_cc: newActivationEmailRecipient.config_value,
            //subject: `New Activation: ${thisAudience?.title} - ${thisUserAudience.reference_id}`, 
            subject: `Thank you for submitting your audience`, 
            template_variables: {
              contact_email: contactEmailConst,
              platform: thisPlatform?.text,
              audience: (thisAudience?.title || '').split('||')[0], 
              timeframe: `${activationJSON.activationStartDate} - ${activationJSON.activationEndDate}`,
              status: 'PENDING',
              account_id: platformAccountIdConst || 'unknown',
              reference_id: uniqueIDConst, 
              billing_rate: '$' + parseFloat(activationJSON.activationBillingRate),
              number_of_records: numberWithCommas(u.records_uploaded)
            }
          }
        );
      }
      else {
        // Request audience build 
        console.log('requesting audience build', currentFilter, thisAudience.audience, thisAudience.uuid);
        try {
          runAudienceBuild({filter: JSON.stringify(currentFilter), audience: thisAudience.audience, unique_id: thisAudience.uuid })
        }
        catch(e) {
          console.log('there was an error running audience build', e);

        }

        // Send acknowlegint submission email
        sendEmail(
          {
            template_name: 'new_activation',
            recipient_to: contactEmailConst,
            recipient_cc: newActivationEmailRecipient.config_value,
            //subject: `New Activation: ${thisAudience?.title} - ${thisUserAudience.reference_id}`, 
            subject: `Thank you for submitting your audience`, 
            template_variables: {
              contact_email: contactEmailConst,
              platform: thisPlatform?.text,
              audience: (thisAudience?.title || '').split('||')[0], 
              timeframe: `${activationJSON.activationStartDate} - ${activationJSON.activationEndDate}`,
              status: 'PENDING',
              account_id: platformAccountIdConst || 'unknown',
              reference_id: uniqueIDConst,
              billing_rate: '$' + parseFloat(activationJSON.activationBillingRate),
              number_of_records: numberWithCommas(u.records_uploaded)
            }
          }
        );
      }
      return platformAdded.data.id; 
    }

  const saveNote = () => {
    console.log('saving note', activationJSON);
    if (activationJSON.activationType == 'platform') { /// Platform activation workflow
      updateUserAudienceNote({type: 'platform', reference_id: newUserAudienceRefId, note: noteRef.current.value, account_name: accountNameRef.current.value, platform_activation_id: newPlatformAId});
    }
    else { // export
      updateUserAudienceNote({type: 'export', reference_id: newUserAudienceRefId, note: noteRef.current.value, account_name: accountNameRef.current.value });
    }
    setShowSave(false);
  }

  return (
    <div className={selectedStep >= 3 ? 'checkout-process' : 'hidden'}>
      {success ? 
        <div className='container p-1 sides-only'>
          <div className='activation-steps activation-step-div'>
            <i className='fa-light fa-circle circle-step'>
              <p><i className='fa fa-check final-activated-check'></i></p>
            </i>
            <p className={activationJSON.activationType == 'platform'? 'inline': 'non_export'}><b>Activation Confirmed</b></p>
            <div className='row w-100'>
              {activationJSON.activationType == 'platform'? <p className='link w-100' onClick={()=>{navigate('/dashboard/your-audiences');}}>View all activations</p> : ""}
            </div>
            <br/>
            <hr className='w-100 mt-0 mb-0 leftside'/>
          </div>
        </div>
        :
        <div className='container p-1 sides-only'>
          <h3 className='w-100'>Enter billing information for {activationJSON.activationType == 'export' ? 'download activation' : 'platform activation'}</h3>
          <hr className='leftside w-100'/>
        </div>
      }
      <ActivatedSummary activationJSON={activationJSON} thisAudience={thisAudience} success={success} /> 
      <div className='container p-1 sides-only'>
        <hr className='leftside w-100'/>
      </div>
      { !success && 
        <div className='container p-1 sides-only'>
          <div className='leftside w-100 payment-panel'>
            {bt}
          </div>
        </div>
      }
      {success && activationJSON.activationType == 'export' ? <div><p className='export-confirmation-p'>To view/download your Export, please navigate to:<button className='export-confirmation' onClick={()=>{navigate('/dashboard/your-audiences');}}>Activations</button></p><p className='export-confirmation-p'>Your export will be ready when you see the Download button</p></div> : ''}
      { success && 
        <div className='container p-1 sides-only'>
          <div className='add-note'>
            <p>Add Notes</p>
            <textarea ref={noteRef} rows={5} cols={150} placeholder='Enter note here' onChange={()=>{setShowSave(true);}}></textarea>
            <input type='text' ref={accountNameRef} placeholder='Add Account Name' onChange={()=>{setShowSave(true);}}></input>
            {showSave && <div className='leftside activation-continue-button mt-0 mb-0' onClick={()=>{saveNote()}}>SAVE</div> }
          </div>
        </div>
      }
      {!success && <div className='activation-footer'>
        <div className='leftside activation-cancel-button'
         onClick={() => {navigate({ pathname: '/dashboard/audience', search: createSearchParams({ uuid: thisAudience.uuid, cView: thisAudience.cluster, custom: thisAudience.custom }).toString() })}}
         >CANCEL</div>
        <div className='leftside activation-cancel-button ml-0' onClick={()=>{setSelectedStep(2); clearBraintree();}}>BACK</div> 
        <div className='rightside activation-continue-button mr-2' onClick={()=>{runPayment()}}>ACTIVATE</div> 
      </div>
      }
    </div>
  )
}

ActivatedStepThree.propTypes = {
  activationJSON: PropTypes.object,
  thisAudience: PropTypes.object,
  thisUserAudience: PropTypes.object,
  setSelectedStep: PropTypes.func,
  selectedStep: PropTypes.number,
  existingMultiFormats: PropTypes.array,
  thisOverrideUserAudience: PropTypes.object, 
  orgData: PropTypes.object
}
